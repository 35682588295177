import React, { useState, useMemo } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { 
  Typography, 
  Button, 
  Box, 
  TextField, 
  Paper,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link,
  Collapse
} from '@mui/material';
import {
  Check as CheckIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import axios from 'axios';
import { setupAxios } from '../utils/auth';
import { API_BASE_URL } from '../config';
import { containsProfanity } from '../utils/profanityFilter';

interface SignupProps {
  onLogin: () => void;
}

interface PasswordRequirement {
  regex: RegExp;
  message: string;
  met: boolean;
}

const passwordRequirements: PasswordRequirement[] = [
  { regex: /.{8,}/, message: 'At least 8 characters long', met: false },
  { regex: /[A-Z]/, message: 'Contains uppercase letter', met: false },
  { regex: /[a-z]/, message: 'Contains lowercase letter', met: false },
  { regex: /[0-9]/, message: 'Contains number', met: false },
  { regex: /[^A-Za-z0-9]/, message: 'Contains special character', met: false },
];

const Signup: React.FC<SignupProps> = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [usernameError, setUsernameError] = useState<string | null>(null);
  const [tosAccepted, setTosAccepted] = useState(false);
  const [tosDialogOpen, setTosDialogOpen] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const navigate = useNavigate();

  const passwordStrength = useMemo(() => {
    return passwordRequirements.filter(req => req.regex.test(password)).length;
  }, [password]);

  const passwordStrengthPercent = (passwordStrength / passwordRequirements.length) * 100;

  const getStrengthColor = () => {
    if (passwordStrengthPercent <= 20) return 'error';
    if (passwordStrengthPercent <= 60) return 'warning';
    return 'success';
  };

  const validateUsername = (value: string): string | null => {
    if (!value) return null;

    if (value.length < 3) {
      return 'Username must be at least 3 characters long';
    }

    if (value.length > 30) {
      return 'Username must be less than 30 characters long';
    }

    if (!/^[a-zA-Z][a-zA-Z0-9_-]*$/.test(value)) {
      return 'Username must start with a letter and can only contain letters, numbers, underscores, and hyphens';
    }

    if (containsProfanity(value, true)) {
      return 'This username contains inappropriate content. Please choose a family-friendly username.';
    }

    return null;
  };

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newUsername = e.target.value;
    setUsername(newUsername);
    
    // Only show validation errors after user has typed something
    if (newUsername) {
      const validationError = validateUsername(newUsername);
      setUsernameError(validationError);
    } else {
      setUsernameError(null);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    
    if (!tosAccepted) {
      setError('Please accept the Terms of Service to continue');
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords don't match");
      return;
    }

    if (passwordStrength < passwordRequirements.length) {
      setError('Please meet all password requirements');
      return;
    }

    // Final username validation before submission
    const usernameValidationError = validateUsername(username);
    if (usernameValidationError) {
      setError(usernameValidationError);
      return;
    }

    try {
      const response = await axios.post(`${API_BASE_URL}/accounts/signup/`, {
        username,
        email,
        password
      });
      
      localStorage.setItem('token', response.data.token);
      setupAxios();
      onLogin();
      navigate('/');
    } catch (error) {
      console.error('Full error object:', error);
      if (axios.isAxiosError(error)) {
        if (error.response) {
          setError(error.response.data.detail || 'An error occurred during signup');
        } else if (error.request) {
          setError('No response received from the server. Please try again.');
        } else {
          setError('Error setting up the request. Please try again.');
        }
      } else {
        setError('An unexpected error occurred');
      }
    }
  };

  const TermsOfService = () => (
    <Dialog 
      open={tosDialogOpen} 
      onClose={() => setTosDialogOpen(false)}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle sx={{ fontWeight: 600 }}>Terms of Service - Storybyte AI Learning Platform</DialogTitle>
      <DialogContent dividers>
        <Typography variant="h6" gutterBottom>1. Service Description</Typography>
        <Typography paragraph>
          Storybyte is an AI-powered educational platform that provides interactive learning experiences through storytelling and adaptive content generation.
        </Typography>

        <Typography variant="h6" gutterBottom>2. AI-Generated Content</Typography>
        <Typography paragraph>
          • Content may be generated using artificial intelligence technologies
          • We do not guarantee the accuracy or completeness of AI-generated content
          • Users should exercise critical thinking and verify important information
        </Typography>

        <Typography variant="h6" gutterBottom>3. Data Collection and Privacy</Typography>
        <Typography paragraph>
          • We collect user interaction data to improve learning experiences
          • Personal information is protected and never sold to third parties
          • Learning patterns are analyzed to enhance educational outcomes
        </Typography>

        <Typography variant="h6" gutterBottom>4. Child Safety and COPPA Compliance</Typography>
        <Typography paragraph>
          • Users under 13 require parental consent
          • Content is filtered for age-appropriateness
          • Enhanced privacy protections for young learners
        </Typography>

        <Typography variant="h6" gutterBottom>5. Intellectual Property</Typography>
        <Typography paragraph>
          • User-generated content remains the property of the user
          • AI-generated content is licensed for educational use only
          • Sharing and modification of content must comply with fair use
        </Typography>

        <Typography variant="h6" gutterBottom>6. Usage Guidelines</Typography>
        <Typography paragraph>
          • Content should be used for educational purposes only
          • No unauthorized distribution of platform materials
          • Respect intellectual property rights of others
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setTosDialogOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Box 
      sx={{ 
        mt: { xs: 12, sm: 14 },
        mx: 'auto',
        px: 2,
        maxWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Paper 
        elevation={3}
        sx={{
          p: 4,
          maxWidth: 400,
          width: '100%',
          borderRadius: 2,
          bgcolor: theme => theme.palette.mode === 'dark' ? 'background.paper' : 'rgba(255, 255, 255, 0.98)',
          backdropFilter: 'blur(10px)',
          border: theme => theme.palette.mode === 'dark' ? '1px solid rgba(255, 255, 255, 0.1)' : 'none'
        }}
      >
        <Typography 
          variant="h4" 
          component="h1" 
          gutterBottom
          sx={{
            fontWeight: 600,
            textAlign: 'center',
            mb: 3,
            color: theme => theme.palette.mode === 'dark' ? 'text.primary' : '#1a1a1a'
          }}
        >
          Create Account
        </Typography>

        <Typography 
          variant="body2" 
          sx={{ 
            mb: 3,
            color: theme => theme.palette.mode === 'dark' ? 'text.secondary' : 'text.primary',
            textAlign: 'center'
          }}
        >
          Join our community and start sharing your work
        </Typography>

        {error && (
          <Typography 
            color="error" 
            sx={{ 
              mb: 2,
              textAlign: 'center',
              backgroundColor: theme => theme.palette.mode === 'dark' 
                ? 'rgba(211, 47, 47, 0.2)' 
                : 'rgba(211, 47, 47, 0.1)',
              p: 1,
              borderRadius: 1
            }}
          >
            {error}
          </Typography>
        )}

        <Box component="form" onSubmit={handleSubmit}>
          <TextField
            label="Username"
            variant="outlined"
            fullWidth
            value={username}
            onChange={handleUsernameChange}
            error={!!usernameError}
            helperText={usernameError || 'Choose a username between 3-30 characters'}
            required
            sx={{ mb: 2 }}
            inputProps={{
              maxLength: 30,
              pattern: "^[a-zA-Z][a-zA-Z0-9_-]*$"
            }}
          />

          <TextField
            fullWidth
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            sx={{
              mb: 2,
              '& .MuiOutlinedInput-root': {
                bgcolor: theme => theme.palette.mode === 'dark' ? 'background.paper' : 'white',
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme => theme.palette.primary.main,
                },
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: theme => theme.palette.primary.main,
              }
            }}
          />

          <Box>
            <TextField
              fullWidth
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onFocus={() => setIsPasswordFocused(true)}
              onBlur={() => {
                if (!password) {
                  setIsPasswordFocused(false);
                }
              }}
              required
              sx={{
                mb: 2,
                '& .MuiOutlinedInput-root': {
                  bgcolor: theme => theme.palette.mode === 'dark' ? 'background.paper' : 'white',
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme => theme.palette.primary.main,
                  },
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: theme => theme.palette.primary.main,
                }
              }}
            />
            <Collapse in={isPasswordFocused || password.length > 0}>
              <Box sx={{ mb: 2 }}>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    mb: 1,
                    color: theme => theme.palette.mode === 'dark' ? 'text.secondary' : 'text.primary'
                  }}
                >
                  Password must contain:
                </Typography>
                <List dense>
                  {passwordRequirements.map((req, index) => (
                    <ListItem key={index} sx={{ p: 0 }}>
                      <ListItemIcon sx={{ minWidth: 36 }}>
                        {req.regex.test(password) ? (
                          <CheckIcon color="success" fontSize="small" />
                        ) : (
                          <CloseIcon color="error" fontSize="small" />
                        )}
                      </ListItemIcon>
                      <ListItemText 
                        primary={req.message} 
                        sx={{ 
                          '& .MuiListItemText-primary': {
                            fontSize: '0.875rem',
                            color: theme => theme.palette.mode === 'dark' ? 'text.secondary' : 'text.primary'
                          }
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
                <LinearProgress 
                  variant="determinate" 
                  value={passwordStrengthPercent}
                  color={getStrengthColor()}
                  sx={{ mt: 1, borderRadius: 1 }}
                />
              </Box>
            </Collapse>
          </Box>

          <TextField
            fullWidth
            label="Confirm Password"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            error={confirmPassword !== '' && password !== confirmPassword}
            helperText={confirmPassword !== '' && password !== confirmPassword ? "Passwords don't match" : ''}
            sx={{
              mb: 2,
              '& .MuiOutlinedInput-root': {
                bgcolor: theme => theme.palette.mode === 'dark' ? 'background.paper' : 'white',
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme => theme.palette.primary.main,
                },
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: theme => theme.palette.primary.main,
              }
            }}
          />

          <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
            <FormControlLabel
              control={
                <Checkbox 
                  checked={tosAccepted}
                  onChange={(e) => setTosAccepted(e.target.checked)}
                  required
                  sx={{
                    color: theme => theme.palette.mode === 'dark' ? 'text.secondary' : 'text.primary',
                    '&.Mui-checked': {
                      color: theme => theme.palette.primary.main,
                    },
                  }}
                />
              }
              label={
                <Typography 
                  variant="body2"
                  sx={{ 
                    color: theme => theme.palette.mode === 'dark' ? 'text.secondary' : 'text.primary'
                  }}
                >
                  I accept the{' '}
                  <Button 
                    onClick={() => setTosDialogOpen(true)}
                    sx={{ 
                      p: 0, 
                      textTransform: 'none',
                      fontWeight: 600,
                      minWidth: 0,
                      color: theme => theme.palette.primary.main,
                      '&:hover': {
                        background: 'none',
                        textDecoration: 'underline'
                      }
                    }}
                  >
                    Terms of Service
                  </Button>
                </Typography>
              }
              sx={{ 
                mb: 2,
                '& .MuiFormControlLabel-asterisk': {
                  display: 'none'
                }
              }}
            />
          </Box>

          <Button
            type="submit"
            fullWidth
            sx={{
              background: 'linear-gradient(45deg, #FF8A00, #FF4F00)',
              color: 'white',
              py: 1.5,
              borderRadius: 2,
              textTransform: 'none',
              fontWeight: 600,
              fontSize: '1rem',
              boxShadow: '0 4px 15px rgba(255, 138, 0, 0.3)',
              backdropFilter: 'blur(10px)',
              border: 'none',
              transition: 'all 0.3s ease',
              '&:hover': {
                background: 'linear-gradient(45deg, #FF9500, #FF5500)',
                boxShadow: '0 6px 20px rgba(255, 138, 0, 0.4)',
                transform: 'translateY(-2px)',
              },
              '&:active': {
                transform: 'translateY(1px)',
                boxShadow: '0 2px 10px rgba(255, 138, 0, 0.2)',
              }
            }}
          >
            Sign Up
          </Button>

          <Box sx={{ mt: 2, textAlign: 'center' }}>
            <Link
              component={RouterLink}
              to="/login"
              sx={{
                color: theme => theme.palette.primary.main,
                textDecoration: 'none',
                fontWeight: 600,
                fontSize: '0.875rem',
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              Already have an account? Log in
            </Link>
          </Box>
        </Box>
      </Paper>
      <TermsOfService />
    </Box>
  );
};

export default Signup;