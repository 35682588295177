import React from 'react';
import { Routes, Route, Link as RouterLink } from 'react-router-dom';
import { Box, Typography, Tabs, Tab, Grid, Card, CardActionArea } from '@mui/material';
import ProfileInfo from './ProfileInfo';
import ProfileStories from './ProfileStories';

const Profile: React.FC = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        My Profile
      </Typography>
      <Tabs value={value} onChange={handleChange} aria-label="profile tabs" centered>
        <Tab label="Profile" component={RouterLink} to="" />
        <Tab label="Stories" component={RouterLink} to="stories" />
      </Tabs>
      <Box sx={{ mt: 2 }}>
        <Routes>
          <Route path="" element={<ProfileInfo />} />
          <Route path="stories" element={<ProfileStories />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default Profile;