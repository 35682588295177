import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Box, Typography, Rating, TextField, Button, Dialog, DialogContent
} from '@mui/material';

interface StoryEndProps {
  open: boolean;
  onClose: () => void;
  title: string;
  onRestart: () => void;
  storyData: {
    rating: number;
    num_ratings: number;
    comments: Array<{
      id: number;
      user?: {
        id: number;
        username: string;
      };
      text: string;
      created_at: string;
      rating: number;
    }>;
  };
}

const StoryEnd: React.FC<StoryEndProps> = ({ open, onClose, title, onRestart, storyData }) => {
  const [userRating, setUserRating] = useState<number | null>(null);
  const [comment, setComment] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (!userRating) return;
    setSubmitting(true);
    try {
      await new Promise(resolve => setTimeout(resolve, 1000)); // Simulate API call
      setSubmitting(false);
      onClose();
      navigate('/');
    } catch (error) {
      console.error('Error submitting review:', error);
      setSubmitting(false);
    }
  };

  return (
    <Dialog 
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          bgcolor: 'rgba(255, 255, 255, 0.95)',
          backdropFilter: 'blur(10px)',
          borderRadius: 2,
        }
      }}
    >
      <DialogContent sx={{ p: 4 }}>
        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <Typography variant="h4" component="h2" gutterBottom>
            {title}
          </Typography>
          <Typography variant="h6" color="text.secondary" gutterBottom>
            Story Complete!
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            Rate this story
          </Typography>
          <Rating
            value={userRating}
            onChange={(_, value) => setUserRating(value)}
            size="large"
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            placeholder="Share your thoughts about the story..."
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          <Button
            fullWidth
            variant="contained"
            onClick={handleSubmit}
            disabled={submitting || !userRating}
            sx={{ mt: 2 }}
          >
            {submitting ? 'Submitting...' : 'Submit Review'}
          </Button>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            Story Stats
          </Typography>
          <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h4">{storyData.rating.toFixed(1)}</Typography>
              <Typography variant="body2" color="text.secondary">Average Rating</Typography>
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h4">{storyData.num_ratings}</Typography>
              <Typography variant="body2" color="text.secondary">Total Ratings</Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            Comments
          </Typography>
          {storyData.comments.map((comment) => (
            <Box key={comment.id} sx={{ mb: 2 }}>
              <Typography variant="body1" paragraph>
                {comment.text}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {comment.user?.username ?? 'Anonymous'} - {new Date(comment.created_at).toLocaleString()}
              </Typography>
            </Box>
          ))}
        </Box>

        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
          <Button
            variant="outlined"
            onClick={onRestart}
            sx={{ minWidth: 120 }}
          >
            Play Again
          </Button>
          <Button
            variant="contained"
            onClick={() => navigate('/')}
            sx={{ minWidth: 120 }}
          >
            Home
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default StoryEnd;