import React from 'react';
import { Box, Typography, Card, CardContent, Grid, styled } from '@mui/material';
import {
  Speed as SpeedIcon,
  Psychology as BrainIcon,
  Diversity3 as DiversityIcon,
  AutoAwesome as CustomizeIcon,
} from '@mui/icons-material';

const benefits = [
  {
    icon: SpeedIcon,
    title: 'Faster Learning',
    description:
      'Interactive storytelling accelerates comprehension through active engagement and personalized pacing',
    gradient: 'linear-gradient(45deg, #FF8A00, #FF4F00)',
  },
  {
    icon: BrainIcon,
    title: 'Better Retention',
    description:
      'Make meaningful choices that reinforce learning and create lasting neural connections',
    gradient: 'linear-gradient(45deg, #FF4F00, #FF8A00)',
  },
  {
    icon: DiversityIcon,
    title: 'Any Level, Any Age',
    description:
      'Sophisticated adaptive content that grows with your knowledge, from basics to advanced topics',
    gradient: 'linear-gradient(45deg, #FF8A00, #FF4F00)',
  },
  {
    icon: CustomizeIcon,
    title: 'Personalized Paths',
    description:
      'Your choices shape the narrative, creating a unique learning experience tailored to your interests',
    gradient: 'linear-gradient(45deg, #FF4F00, #FF8A00)',
  },
];

// Define the types for the custom props
interface AnimatedCardProps {
  index: number;
}

interface IconWrapperProps {
  gradient: string;
}

const StyledBox = styled(Box)(({ theme }) => ({
  maxWidth: '1400px',
  margin: '0 auto',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));

const BackgroundBox = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(6),
  background: theme.palette.mode === 'dark' 
    ? 'rgba(18,18,18,0.97)'
    : 'rgba(252,253,254,0.97)',
  backdropFilter: 'blur(10px)',
  borderRadius: '20px',
  position: 'relative',
  overflow: 'hidden',
  boxShadow: theme.palette.mode === 'dark'
    ? '0 4px 20px rgba(0,0,0,0.2)'
    : '0 4px 20px rgba(0,0,0,0.06)',
}));

const AnimatedCard = styled(Card)<AnimatedCardProps>(({ theme, index }) => ({
  height: '100%',
  background: theme.palette.mode === 'dark'
    ? 'rgba(38,38,38,0.9)'
    : 'rgba(255,255,255,0.9)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.spacing(1),
  overflow: 'visible',
  position: 'relative',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  animation: `fadeSlideUp 0.5s ease-out ${index * 0.15}s both`,
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: theme.palette.mode === 'dark'
      ? '0 8px 25px rgba(0,0,0,0.3)'
      : '0 8px 25px rgba(0,0,0,0.15)',
  },
  '@keyframes fadeSlideUp': {
    '0%': {
      opacity: 0,
      transform: 'translateY(20px)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
}));

const IconWrapper = styled(Box)<{ gradient: string }>(({ gradient }) => ({
  width: 64,
  height: 64,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  background: gradient,
  marginBottom: 16,
  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
}));

const WhyStorybyte = () => {
  return (
    <StyledBox>
      <BackgroundBox>
        <Grid
          container
          spacing={3}
          sx={{
            px: { xs: 2, sm: 4 },
            position: 'relative',
            zIndex: 1,
          }}
        >
          {benefits.map((benefit, index) => (
            <Grid item xs={12} sm={6} md={3} key={benefit.title}>
              <AnimatedCard index={index}>
                <CardContent sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  p: 3,
                }}>
                  <IconWrapper gradient={benefit.gradient}>
                    <benefit.icon sx={{ 
                      fontSize: 28,
                      color: 'white',
                      filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.2))',
                    }} />
                  </IconWrapper>

                  <Typography
                    variant="h6"
                    sx={theme => ({
                      fontWeight: 600,
                      mb: 2,
                      fontSize: { xs: '1.125rem', md: '1.25rem' },
                      color: theme.palette.mode === 'dark' ? '#fff' : '#000',
                    })}
                  >
                    {benefit.title}
                  </Typography>

                  <Typography
                    variant="body2"
                    sx={theme => ({
                      lineHeight: 1.6,
                      fontSize: { xs: '0.825rem', md: '0.875rem' },
                      color: theme.palette.mode === 'dark' 
                        ? 'rgba(255,255,255,0.7)' 
                        : 'rgba(0,0,0,0.7)',
                    })}
                  >
                    {benefit.description}
                  </Typography>
                </CardContent>
              </AnimatedCard>
            </Grid>
          ))}
        </Grid>
      </BackgroundBox>
    </StyledBox>
  );
};

export default WhyStorybyte;
