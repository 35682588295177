import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  Tabs,
  Tab,
  IconButton,
  Tooltip,
  Chip,
  useTheme,
} from '@mui/material';
import {
  Add as AddIcon,
  School as SchoolIcon,
  ContentCopy as ContentCopyIcon,
  People as PeopleIcon,
  AutoStories as StoriesIcon,
} from '@mui/icons-material';
import axios from 'axios';
import { API_BASE_URL } from '../constants';

interface ClassType {
  id: number;
  name: string;
  description: string;
  students_count: number;
  stories_count: number;
  owner: string;
  class_code?: string;
}

const ClassManager = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState(0);
  const [ownedClasses, setOwnedClasses] = useState<ClassType[]>([]);
  const [enrolledClasses, setEnrolledClasses] = useState<ClassType[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [joinDialogOpen, setJoinDialogOpen] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [classCode, setClassCode] = useState('');
  const [newClassName, setNewClassName] = useState('');
  const [newClassDescription, setNewClassDescription] = useState('');

  // Fetch classes
  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/classes/my_classes/`);
        setOwnedClasses(response.data.owned_classes);
        setEnrolledClasses(response.data.enrolled_classes);
      } catch (error) {
        setError('Failed to load classes');
        console.error('Error fetching classes:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchClasses();
  }, []);

  const handleJoinClass = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/classes/${classCode}/enroll/`);
      if (response.status === 200) {
        // Refresh classes after successful enrollment
        const classesResponse = await axios.get(`${API_BASE_URL}/classes/my_classes/`);
        setEnrolledClasses(classesResponse.data.enrolled_classes);
        setJoinDialogOpen(false);
        setClassCode('');
      }
    } catch (error) {
      setError('Failed to join class. Please check the class code.');
    }
  };

  const handleCreateClass = async () => {
    try {
      await axios.post(`${API_BASE_URL}/classes/`, {
        name: newClassName,
        description: newClassDescription
      });
      // Refresh classes after successful creation
      const response = await axios.get(`${API_BASE_URL}/classes/my_classes/`);
      setOwnedClasses(response.data.owned_classes);
      setCreateDialogOpen(false);
      setNewClassName('');
      setNewClassDescription('');
    } catch (error) {
      setError('Failed to create class. Please try again.');
    }
  };

  const handleCopyCode = (code: string) => {
    navigator.clipboard.writeText(code);
  };

  const ClassCard = ({ class_ }: { class_: ClassType }) => (
    <Card sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
      '&:hover': {
        transform: 'translateY(-4px)',
        boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
      }
    }}>
      <CardActionArea 
        onClick={() => navigate(`/classes/${class_.id}`)}
        sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}
      >
        <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h6" gutterBottom>{class_.name}</Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2, flexGrow: 1 }}>
            {class_.description}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 'auto' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              <PeopleIcon fontSize="small" color="action" />
              <Typography variant="body2" color="text.secondary">
                {class_.students_count}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              <StoriesIcon fontSize="small" color="action" />
              <Typography variant="body2" color="text.secondary">
                {class_.stories_count}
              </Typography>
            </Box>
            {class_.class_code && (
              <Tooltip title="Copy class code">
                <IconButton 
                  size="small" 
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCopyCode(class_.class_code!);
                  }}
                  sx={{ ml: 'auto' }}
                >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );

  return (
    <Box sx={{ pt: 10 }}>
      <Container maxWidth="lg">
        {/* Header */}
        <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h4" sx={{ fontWeight: 600 }}>Classes</Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              variant="contained"
              onClick={() => setJoinDialogOpen(true)}
              startIcon={<SchoolIcon />}
              sx={{
                bgcolor: theme.palette.mode === 'dark' ? '#333' : '#1a1a1a',
                color: 'white',
                '&:hover': {
                  bgcolor: theme.palette.mode === 'dark' ? '#444' : '#333',
                }
              }}
            >
              Join Class
            </Button>
            <Button
              variant="contained"
              onClick={() => setCreateDialogOpen(true)}
              startIcon={<AddIcon />}
              sx={{
                background: theme.palette.mode === 'dark' 
                  ? 'linear-gradient(45deg, #FF8A00, #FF4F00)'
                  : '#FF4F00',
                color: 'white',
                '&:hover': {
                  background: theme.palette.mode === 'dark'
                    ? 'linear-gradient(45deg, #FF9500, #FF6000)'
                    : '#FF6000',
                }
              }}
            >
              Create Class
            </Button>
          </Box>
        </Box>

        {error && (
          <Alert severity="error" onClose={() => setError(null)} sx={{ mb: 4 }}>
            {error}
          </Alert>
        )}

        {/* Tabs */}
        <Tabs
          value={activeTab}
          onChange={(_, newValue) => setActiveTab(newValue)}
          sx={{ mb: 4, borderBottom: 1, borderColor: 'divider' }}
        >
          <Tab 
            label={`Your Classes (${ownedClasses.length})`}
            sx={{ textTransform: 'none' }}
          />
          <Tab 
            label={`Enrolled Classes (${enrolledClasses.length})`}
            sx={{ textTransform: 'none' }}
          />
        </Tabs>

        {/* Class Grid */}
        <Grid container spacing={3}>
          {(activeTab === 0 ? ownedClasses : enrolledClasses).map((class_) => (
            <Grid item xs={12} sm={6} md={4} key={class_.id}>
              <ClassCard class_={class_} />
            </Grid>
          ))}
        </Grid>

        {/* Join Class Dialog */}
        <Dialog open={joinDialogOpen} onClose={() => setJoinDialogOpen(false)}>
          <DialogTitle>Join a Class</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Class Code"
              fullWidth
              value={classCode}
              onChange={(e) => setClassCode(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setJoinDialogOpen(false)}>Cancel</Button>
            <Button 
              onClick={handleJoinClass}
              variant="contained"
              disabled={!classCode.trim()}
            >
              Join
            </Button>
          </DialogActions>
        </Dialog>

        {/* Create Class Dialog */}
        <Dialog open={createDialogOpen} onClose={() => setCreateDialogOpen(false)}>
          <DialogTitle>Create a New Class</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Class Name"
              fullWidth
              value={newClassName}
              onChange={(e) => setNewClassName(e.target.value)}
            />
            <TextField
              margin="dense"
              label="Description"
              fullWidth
              multiline
              rows={3}
              value={newClassDescription}
              onChange={(e) => setNewClassDescription(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCreateDialogOpen(false)}>Cancel</Button>
            <Button 
              onClick={handleCreateClass}
              variant="contained"
              disabled={!newClassName.trim()}
            >
              Create
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Box>
  );
};

export default ClassManager;
