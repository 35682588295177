import React, { useState, useEffect, useCallback } from 'react';
import { 
  Box, 
  Typography, 
  IconButton, 
  Button, 
  Avatar, 
  Chip,
  CircularProgress,
  Alert,
  Menu,
  MenuItem,
  Container,
  Card,
  CardContent,
  Divider,
  Grid,
} from '@mui/material';
import {
  Favorite,
  FavoriteBorder,
  Share,
  BookmarkAdd,
  BookmarkAdded,
  TrendingUp,
  School,
  Public as PublicIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

interface StoryPost {
  id: number;
  title: string;
  context: string;
  plays: number;
  likes: number;
  author?: string;
  created_at: string;
  current_node?: {
    image_url: string;
  };
  is_liked: boolean;
  is_saved: boolean;
  like_count: number;
}

const API_BASE_URL = process.env.REACT_APP_API_URL || '/api';
const S3_BUCKET_URL = 'https://storybyte1.s3.amazonaws.com';
const FALLBACK_IMAGE = '/static/images/fallback_cover.png';

const Public: React.FC = () => {
  const navigate = useNavigate();
  const [posts, setPosts] = useState<StoryPost[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [sortBy, setSortBy] = useState<'recent' | 'popular'>('recent');

  const fetchPosts = useCallback(async () => {
    setLoading(true);
    try {
      const endpoint = sortBy === 'recent' ? 'stories/' : 'stories/popular/';
      const response = await axios.get(`${API_BASE_URL}/${endpoint}`);
      setPosts(response.data);
    } catch (error) {
      console.error('Error fetching posts:', error);
      setError('Failed to load posts. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, [sortBy]);

  useEffect(() => {
    fetchPosts();
  }, [fetchPosts]);

  const getImageUrl = (story: StoryPost): string => {
    if (story.current_node?.image_url) {
      if (story.current_node.image_url.startsWith('http')) return story.current_node.image_url;
      if (story.current_node.image_url.startsWith('data:')) return story.current_node.image_url;
      return `${S3_BUCKET_URL}/${story.current_node.image_url}`;
    }
    return FALLBACK_IMAGE;
  };

  const ImageWithFallback: React.FC<{ story: StoryPost }> = ({ story }) => {
    const [imgSrc, setImgSrc] = useState(() => getImageUrl(story));

    return (
      <Box
        component="img"
        src={imgSrc}
        alt={story.title}
        onError={() => {
          if (imgSrc !== FALLBACK_IMAGE) {
            console.error('Failed to load image:', imgSrc);
            setImgSrc(FALLBACK_IMAGE);
          }
        }}
        sx={{
          width: '100%',
          borderRadius: 4,
          objectFit: 'cover',
          aspectRatio: '16/9',
        }}
      />
    );
  };

  const handleLike = async (postId: number, event: React.MouseEvent) => {
    event.stopPropagation();
    try {
      const response = await axios.post(`${API_BASE_URL}/stories/${postId}/like/`);
      setPosts(prevPosts => 
        prevPosts.map(post => 
          post.id === postId ? {
            ...post,
            is_liked: response.data.is_liked,
            like_count: response.data.like_count
          } : post
        )
      );
    } catch (error) {
      console.error('Error liking post:', error);
    }
  };

  const handleSave = async (postId: number, event: React.MouseEvent) => {
    event.stopPropagation();
    try {
      const response = await axios.post(`${API_BASE_URL}/stories/${postId}/save/`);
      setPosts(prevPosts => 
        prevPosts.map(post => 
          post.id === postId ? {
            ...post,
            is_saved: response.data.is_saved
          } : post
        )
      );
    } catch (error) {
      console.error('Error saving post:', error);
    }
  };

  const handleShare = async (post: StoryPost, event: React.MouseEvent) => {
    event.stopPropagation();
    try {
      const shareData = {
        title: post.title,
        text: `Check out this story on Storybyte: ${post.title}`,
        url: `${window.location.origin}/story/${post.id}`
      };
      
      if (navigator.share) {
        await navigator.share(shareData);
      } else {
        await navigator.clipboard.writeText(shareData.url);
      }
    } catch (error) {
      console.error('Error sharing post:', error);
    }
  };

  const handlePostClick = (postId: number) => {
    navigate(`/story/${postId}`);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ mt: 4, px: 2 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      {/* Header Section */}
      <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <PublicIcon sx={{ fontSize: 32, color: 'primary.main' }} />
          <Typography variant="h4" component="h1" sx={{ fontWeight: 600 }}>
            Public Feed
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant={sortBy === 'recent' ? 'contained' : 'outlined'}
            onClick={() => setSortBy('recent')}
            size="small"
            sx={{ minWidth: 100 }}
          >
            Recent
          </Button>
          <Button
            variant={sortBy === 'popular' ? 'contained' : 'outlined'}
            onClick={() => setSortBy('popular')}
            size="small"
            sx={{ minWidth: 100 }}
          >
            Popular
          </Button>
        </Box>
      </Box>

      {/* Grid Layout */}
      <Grid container spacing={3}>
        {posts.map((post) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={post.id}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 4,
                transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                cursor: 'pointer',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: 4,
                },
              }}
              onClick={() => handlePostClick(post.id)}
            >
              <Box sx={{ position: 'relative', paddingTop: '56.25%' }}>
                <Box
                  component="img"
                  src={getImageUrl(post)}
                  alt={post.title}
                  onError={(e: any) => {
                    if (e.target.src !== FALLBACK_IMAGE) {
                      e.target.src = FALLBACK_IMAGE;
                    }
                  }}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
              </Box>
              <CardContent sx={{ flexGrow: 1, p: 2 }}>
                {/* Author Section */}
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5, gap: 1 }}>
                  <Avatar sx={{ width: 32, height: 32 }}>
                    {post.author?.[0] || 'A'}
                  </Avatar>
                  <Box>
                    <Typography variant="subtitle2">
                      {post.author || 'Anonymous'}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {new Date(post.created_at).toLocaleDateString()}
                    </Typography>
                  </Box>
                </Box>

                {/* Title and Context */}
                <Typography variant="h6" sx={{ mb: 1, fontSize: '1.1rem' }}>
                  {post.title}
                </Typography>
                <Typography 
                  variant="body2" 
                  color="text.secondary" 
                  sx={{ 
                    mb: 2,
                    display: '-webkit-box',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {post.context}
                </Typography>

                {/* Stats */}
                <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
                  <Chip
                    size="small"
                    icon={<TrendingUp sx={{ fontSize: 16 }} />}
                    label={`${post.plays} plays`}
                  />
                  <Chip
                    size="small"
                    icon={<School sx={{ fontSize: 16 }} />}
                    label={`${post.like_count} likes`}
                  />
                </Box>

                {/* Actions */}
                <Divider sx={{ mb: 1.5 }} />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <IconButton 
                      size="small" 
                      onClick={(e) => handleLike(post.id, e)}
                    >
                      {post.is_liked ? (
                        <Favorite fontSize="small" color="error" />
                      ) : (
                        <FavoriteBorder fontSize="small" />
                      )}
                    </IconButton>
                    <IconButton 
                      size="small"
                      onClick={(e) => handleShare(post, e)}
                    >
                      <Share fontSize="small" />
                    </IconButton>
                  </Box>
                  <IconButton 
                    size="small"
                    onClick={(e) => handleSave(post.id, e)}
                  >
                    {post.is_saved ? (
                      <BookmarkAdded fontSize="small" color="primary" />
                    ) : (
                      <BookmarkAdd fontSize="small" />
                    )}
                  </IconButton>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Post Options Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => setAnchorEl(null)}>Report</MenuItem>
      </Menu>
    </Container>
  );
};

export default Public;