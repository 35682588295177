import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, TextField, Button, FormControlLabel, Switch, Alert, CircularProgress, Container } from '@mui/material';
import axios from 'axios';
import { API_BASE_URL } from '../constants';

const CreateClass: React.FC = () => {
  const [className, setClassName] = useState('');
  const [classDescription, setClassDescription] = useState('');
  const [isPublic, setIsPublic] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleCreateClass = async () => {
    if (!className.trim()) {
      setError('Class name is required');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(`${API_BASE_URL}/classes/`, { 
        name: className.trim(), 
        description: classDescription.trim(),
        is_public: isPublic
      });

      // After successful creation, fetch updated class data
      try {
        await axios.get(`${API_BASE_URL}/classes/my_classes/`);
      } catch (error) {
        console.error('Error refreshing class data:', error);
      }

      // Navigate to the newly created class
      navigate(`/classes/${response.data.id}`);
    } catch (error: any) {
      console.error('Error creating class:', error);
      setError(error.response?.data?.error || 'Failed to create class. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ pt: 10 }}>
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom>Create a New Class</Typography>
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        <TextField
          fullWidth
          label="Class Name"
          value={className}
          onChange={(e) => setClassName(e.target.value)}
          margin="normal"
          required
        />
        <TextField
          fullWidth
          label="Class Description"
          value={classDescription}
          onChange={(e) => setClassDescription(e.target.value)}
          margin="normal"
          multiline
          rows={3}
        />
        <FormControlLabel
          control={
            <Switch
              checked={isPublic}
              onChange={(e) => setIsPublic(e.target.checked)}
              name="isPublic"
            />
          }
          label="Public Class"
        />
        <Box sx={{ mt: 2 }}>
          <Button 
            variant="contained" 
            onClick={handleCreateClass}
            disabled={loading || !className}
          >
            {loading ? <CircularProgress size={24} /> : 'Create Class'}
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default CreateClass;