import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const UniversalBackground: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const theme = useTheme();
  
  return (
    <Box sx={{ 
      position: 'relative', 
      minHeight: '100vh',
      width: '100%',
      overflow: 'hidden'
    }}>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 0,
          background: `linear-gradient(135deg, 
            ${theme.palette.mode === 'dark' ? '#0A1929' : '#E3F2FD'} 0%, 
            ${theme.palette.mode === 'dark' ? '#2C1810' : '#FFF3E0'} 100%)`,
          opacity: 0.95,
          pointerEvents: 'none',
        }}
      />
      <Box sx={{ 
        position: 'relative', 
        zIndex: 1,
        minHeight: '100vh',
        width: '100%',
      }}>
        {children}
      </Box>
    </Box>
  );
};

export default UniversalBackground;
