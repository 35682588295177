import React, { useState, useEffect } from 'react';
import { useNavigate, Link as RouterLink, Link } from 'react-router-dom';
import {
  Typography,
  Button,
  Box,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  TextField,
  Chip,
  Avatar,
  LinearProgress,
  FormControl,
  Select,
  MenuItem,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
  useTheme,
  Paper,
} from "@mui/material";
import {
  Create,
  TrendingUp,
  School,
  People,
  MenuBook,
  ArrowForward,
  Search,
  Schedule,
  Star,
} from "@mui/icons-material";
import { styled } from '@mui/material/styles';

interface DashboardProps {
  popularClasses?: Array<{
    id: number;
    name: string;
    description: string;
    students_count: number;
    stories_count: number;
    owner: string;
  }>;
  exploreStories?: Array<{
    id: number;
    title: string;
    context: string;
    plays: number;
    likes: number;
    author?: string;
    current_node?: {
      image_url: string;
      scene_number?: number;
    };
  }>;
  myClasses?: Array<{
    id: number;
    name: string;
    description: string;
    students_count: number;
    stories_count: number;
    owner: string;
  }>;
  myStories?: Array<{
    id: number;
    title: string;
    context: string;
    plays: number;
    likes: number;
    author?: string;
    current_node?: {
      image_url: string;
      scene_number?: number;
    };
  }>;
  handleAction: (path: string) => void;
  quickTitle: string;
  setQuickTitle: (title: string) => void;
  handleQuickCreate: () => void;
  ImageWithFallback: React.FC<{
    src?: string;
    alt: string;
    className?: string;
    style?: React.CSSProperties;
  }>;
}

const DashboardContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '1400px',
  margin: '0 auto',
  padding: theme.spacing(3),
  paddingTop: theme.spacing(10), // Add top padding to prevent navbar overlap
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '1400px',
  padding: `0 ${theme.spacing(3)}`,
  margin: '0 auto',
  background: 'transparent',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  background: theme.palette.background.paper,
  borderRadius: theme.spacing(2),
  boxShadow: '0 8px 32px rgba(0,0,0,0.1)',
}));

const StyledCard = styled(Card)(({ theme }) => ({
  background: theme.palette.background.paper,
  borderRadius: theme.spacing(2),
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 8px 24px rgba(0,0,0,0.15)',
  },
}));

const Dashboard: React.FC<DashboardProps> = ({
  popularClasses = [],
  exploreStories = [],
  myStories = [],
  myClasses = [],
  handleAction,
  quickTitle,
  setQuickTitle,
  handleQuickCreate,
  ImageWithFallback,
}) => {
  const theme = useTheme();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    console.log('Dashboard mounted with props:', {
      popularClasses,
      exploreStories,
      myStories,
      myClasses
    });

    // Validate data structure
    if (!Array.isArray(popularClasses) || !Array.isArray(exploreStories) || 
        !Array.isArray(myStories) || !Array.isArray(myClasses)) {
      setError('Invalid data structure received');
      console.error('Invalid data structure:', {
        popularClasses,
        exploreStories,
        myStories,
        myClasses
      });
    }
  }, [popularClasses, exploreStories, myStories, myClasses]);

  if (error) {
    return (
      <Box sx={{ p: 4, textAlign: 'center' }}>
        <Typography color="error">Error loading dashboard data. Please refresh the page.</Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>{error}</Typography>
      </Box>
    );
  }

  const QuickStartBackground = () => (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        opacity: 0.1,
        background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
        borderRadius: 'inherit',
      }}
    />
  );

  const DiscordButton = () => (
    <Button
      component={RouterLink}
      to="https://discord.gg/your-discord-link"
      target="_blank"
      rel="noopener noreferrer"
      variant="contained"
      color="primary"
      startIcon={<People />}
      sx={{
        mt: 2,
        backgroundColor: '#7289DA', // Discord color
        '&:hover': {
          backgroundColor: '#5b73c7',
        },
      }}
    >
      Join Discord
    </Button>
  );

  const renderClassGrid = (classes: DashboardProps['popularClasses'], title: string) => {
    if (!classes || classes.length === 0) {
      return (
        <Box sx={{ mb: 4, maxWidth: 'lg', mx: 'auto', width: '100%' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
              {title}
            </Typography>
          </Box>
          <StyledPaper
            sx={{
              p: 3,
              textAlign: 'center',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <Box sx={{ py: 2 }}>
              <Typography variant="h6" gutterBottom sx={{ color: 'text.primary' }}>
                No classes available yet
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2 }}>
                Be the first to create one!
              </Typography>
              <Button
                component={RouterLink}
                to="/create-class"
                variant="contained"
                sx={{
                  bgcolor: theme.palette.mode === 'dark' ? '#FF4F00' : '#FF4F00',
                  color: 'white',
                  '&:hover': {
                    bgcolor: theme.palette.mode === 'dark' ? '#FF6000' : '#FF6000',
                  },
                }}
              >
                Create Class
              </Button>
            </Box>
          </StyledPaper>
        </Box>
      );
    }

    return (
      <Box>
        <Typography variant="h4" gutterBottom align="center" sx={{ mb: 4, fontWeight: 600 }}>
          {title}
        </Typography>
        <Grid 
          container 
          spacing={3}
          justifyContent="center"
        >
          {classes.map((classItem) => (
            <Grid 
              item 
              xs={12}
              sm={6}
              md={4} 
              lg={3}
              key={classItem.id}
            >
              <StyledCard
                sx={{
                  height: "auto",
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: "280px",
                  width: "100%",
                  mx: 'auto',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: '0 8px 24px rgba(0,0,0,0.15)',
                  }
                }}
              >
                <CardActionArea
                  onClick={() => handleAction(`/classes/${classItem.id}`)}
                  sx={{ flexGrow: 1, p: 1.5 }}
                >
                  <Box
                    sx={{
                      mb: 1.5,
                      p: 1.5,
                      borderRadius: 2,
                      background: 'linear-gradient(45deg, rgba(33,150,243,0.1), rgba(33,150,243,0.2))',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <School sx={{ fontSize: 32, color: '#2196F3' }} />
                  </Box>
                  <Typography 
                    gutterBottom 
                    variant="h6" 
                    component="div"
                    sx={{ 
                      fontWeight: 600,
                      height: 48,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                      fontSize: '1rem',
                      mb: 1,
                    }}
                  >
                    {classItem.name}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mt: 'auto',
                      flexWrap: "wrap",
                      gap: 1,
                    }}
                  >
                    <Chip
                      avatar={<Avatar sx={{ bgcolor: '#2196F3', width: 24, height: 24, fontSize: '0.8rem' }}>{classItem.owner[0].toUpperCase()}</Avatar>}
                      label={classItem.owner}
                      variant="outlined"
                      size="small"
                      sx={{ borderRadius: 2, height: 28 }}
                    />
                    <Box sx={{ display: 'flex', gap: 0.5 }}>
                      <Chip
                        icon={<People sx={{ color: '#2196F3', fontSize: '1rem' }} />}
                        label={`${classItem.students_count}`}
                        size="small"
                        sx={{ mr: 1, borderRadius: 2, bgcolor: 'rgba(33,150,243,0.1)', height: 28 }}
                      />
                      <Chip
                        icon={<MenuBook sx={{ color: '#2196F3', fontSize: '1rem' }} />}
                        label={`${classItem.stories_count}`}
                        size="small"
                        sx={{ borderRadius: 2, bgcolor: 'rgba(33,150,243,0.1)', height: 28 }}
                      />
                    </Box>
                  </Box>
                </CardActionArea>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  const renderStoryGrid = (stories: DashboardProps['exploreStories'], title: string) => (
    <Box>
      <Typography variant="h4" gutterBottom align="center" sx={{ mb: 4 }}>
        {title}
      </Typography>
      <Grid 
        container 
        spacing={3}
        justifyContent="center"
      >
        {stories.slice(0, 7).map((story) => (
          <Grid 
            item 
            xs={12}
            sm={6}
            md={4} 
            lg={3}
            key={story.id}
          >
            <StyledCard
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                maxWidth: "320px",
                width: "100%",
                mx: 'auto',
                transition: 'all 0.3s ease',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: '0 8px 24px rgba(0,0,0,0.15)',
                }
              }}
            >
              <CardActionArea
                onClick={() => handleAction(`/story/${story.id}`)}
              >
                <Box sx={{ position: 'relative', paddingTop: '56.25%' }}>
                  <Box
                    component="img"
                    src={story.current_node?.image_url || '/static/images/fallback_cover.png'}
                    alt={story.title}
                    onError={(e: any) => {
                      if (e.target.src !== '/static/images/fallback_cover.png') {
                        e.target.src = '/static/images/fallback_cover.png';
                      }
                    }}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </Box>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h6" component="div">
                    {story.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2 }}
                  >
                    {story.context.substring(0, 100)}...
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Chip
                      avatar={<Avatar>{story.author?.[0] || "A"}</Avatar>}
                      label={story.author || "Anonymous"}
                      variant="outlined"
                      sx={{ borderRadius: 4 }}
                    />
                    <Box>
                      <Chip
                        icon={<TrendingUp />}
                        label={`${story.plays}`}
                        size="small"
                        sx={{ mr: 1, borderRadius: 4 }}
                      />
                      <Chip
                        icon={<School />}
                        label={`${story.likes}`}
                        size="small"
                        sx={{ borderRadius: 4 }}
                      />
                    </Box>
                  </Box>
                </CardContent>
              </CardActionArea>
            </StyledCard>
          </Grid>
        ))}
        
        {stories.length > 7 && (
          <Grid 
            item 
            xs={12}
            sm={6}
            md={4} 
            lg={3}
          >
            <StyledCard
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                maxWidth: "320px",
                width: "100%",
                mx: 'auto',
                background: 'linear-gradient(45deg, rgba(33,150,243,0.1), rgba(33,150,243,0.2))',
                border: '2px dashed rgba(33,150,243,0.3)',
                transition: 'all 0.3s ease',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: '0 8px 24px rgba(33,150,243,0.15)',
                  background: 'linear-gradient(45deg, rgba(33,150,243,0.15), rgba(33,150,243,0.25))',
                }
              }}
            >
              <CardActionArea
                onClick={() => handleAction(title === "Explore Stories" ? "/explore" : "/library")}
                sx={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  p: 4
                }}
              >
                <Box sx={{ textAlign: 'center' }}>
                  <Typography 
                    variant="h4" 
                    color="primary" 
                    sx={{ 
                      mb: 2,
                      fontWeight: 600
                    }}
                  >
                    ...
                  </Typography>
                  <Typography 
                    variant="body1" 
                    color="primary"
                    sx={{ 
                      fontWeight: 500 
                    }}
                  >
                    View All Stories
                  </Typography>
                  <Typography 
                    variant="body2" 
                    color="text.secondary"
                    sx={{ mt: 1 }}
                  >
                    {stories.length - 7} more {stories.length - 7 === 1 ? 'story' : 'stories'}
                  </Typography>
                </Box>
              </CardActionArea>
            </StyledCard>
          </Grid>
        )}
      </Grid>
    </Box>
  );

  // Add state for filters
  const [searchQuery, setSearchQuery] = useState("");
  const [educationLevel, setEducationLevel] = useState("");
  const [sortBy, setSortBy] = useState("popular");

  // Filter stories based on search query
  const filterStories = (stories: DashboardProps['exploreStories']) => {
    if (!Array.isArray(stories)) return [];
    return stories.filter(story => 
      story?.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      story?.context?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  // Sort stories based on selected option
  const sortStories = (stories: DashboardProps['exploreStories']) => {
    if (!Array.isArray(stories)) return [];
    switch (sortBy) {
      case "popular":
        return [...stories].sort((a, b) => (b.plays || 0) - (a.plays || 0));
      case "recent":
        return stories; // Assuming stories are already sorted by date
      case "rated":
        return [...stories].sort((a, b) => (b.likes || 0) - (a.likes || 0));
      default:
        return stories;
    }
  };

  // Calculate some basic stats from existing data
  const storiesCompleted = myStories.length;
  const activeClasses = myClasses.length;
  const learningStreak = 1; // Set to 1 day streak
  const lastReadStory = myStories[0]; // Assume first story is most recent

  // Handler for search input
  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  // Handler for sort change
  const handleSortChange = (value: string | null) => {
    if (value) {
      setSortBy(value);
    }
  };

  // Handler for education level change
  const handleFilterChange = (type: string, value: string) => {
    setEducationLevel(value);
  };

  // Add a new function to render continue reading cards
  const renderContinueReadingSection = () => {
    const inProgressStories = myStories.slice(0, 8).map((story) => ({
      ...story,
      scene_number: story.current_node?.scene_number || 1,
      lastRead: !story.current_node?.scene_number ? 'Not started' :
               story.current_node.scene_number === 1 ? 'Just started' : 
               story.current_node.scene_number === 8 ? 'Almost done!' :
               story.current_node.scene_number === 2 ? 'Recently started' :
               `Scene ${story.current_node.scene_number} of 8`
    }));

    return (
      <Box sx={{ width: '100%', py: 4, maxWidth: "1400px", mx: "auto", mb: 4 }}>
        <Typography variant="h4" gutterBottom sx={{ mb: 3, fontWeight: 600 }}>
          Continue Your Journey
        </Typography>
        <Grid container spacing={3}>
          {inProgressStories.map((story) => (
            <Grid item xs={12} sm={6} md={3} key={story.id}>
              <StyledCard
                onClick={() => handleAction(`/story/${story.id}`)}
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'all 0.3s ease',
                  cursor: 'pointer',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: '0 8px 24px rgba(0,0,0,0.15)',
                  },
                  position: 'relative',
                  overflow: 'hidden',
                  minHeight: '180px',
                }}
              >
                {/* Background Image with Gradient Overlay */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    height: '100%',
                    background: `linear-gradient(180deg, 
                      rgba(0,0,0,0) 0%, 
                      rgba(0,0,0,0.7) 50%, 
                      rgba(0,0,0,0.9) 100%
                    )`,
                    zIndex: 1,
                  }}
                />
                <Box
                  component="img"
                  src={story.current_node?.image_url || '/static/images/fallback_cover.png'}
                  alt={story.title}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
                
                {/* Content */}
                <CardContent 
                  sx={{ 
                    position: 'relative', 
                    zIndex: 2,
                    mt: 'auto',
                    color: 'white',
                    p: 1.5,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    '&:last-child': { pb: 1.5 }, 
                  }}
                >
                  <Box sx={{ flex: 1 }}>
                    <Typography 
                      variant="caption" 
                      sx={{ 
                        color: 'rgba(255,255,255,0.7)',
                        display: 'block',
                        mb: 0.25,
                        fontSize: '0.7rem',
                      }}
                    >
                      {story.lastRead}
                    </Typography>
                    <Typography 
                      variant="h6" 
                      sx={{ 
                        fontWeight: 600,
                        textShadow: '0 2px 4px rgba(0,0,0,0.2)',
                        mb: 0.5,
                        fontSize: '1rem',
                        lineHeight: 1.2,
                      }}
                    >
                      {story.title}
                    </Typography>
                  </Box>

                  {/* Progress Bar */}
                  <Box sx={{ mt: 'auto' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.25 }}>
                      <Typography variant="caption" color="text.secondary">
                        Scene {story.current_node?.scene_number || 1} of 8
                      </Typography>
                    </Box>
                    <Box sx={{ 
                      display: 'flex', 
                      gap: '2px',
                      width: '100%',
                    }}>
                      {[...Array(8)].map((_, index) => (
                        <Box
                          key={index}
                          sx={{
                            flex: 1,
                            height: 3,
                            borderRadius: 1,
                            bgcolor: 'rgba(255,255,255,0.2)',
                            position: 'relative',
                            overflow: 'hidden',
                            '&::after': {
                              content: '""',
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              bgcolor: index < (story.current_node?.scene_number || 1)
                                ? theme.palette.mode === 'dark' ? '#FF4F00' : '#FF4F00'
                                : 'transparent',
                              transition: 'background-color 0.3s ease'
                            }
                          }}
                        />
                      ))}
                    </Box>
                  </Box>
                </CardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  return (
    <DashboardContainer>
      {/* Quick Start Section */}
      <Box sx={{ mb: 4, position: 'relative' }}>
        <QuickStartBackground />
        <Box sx={{ p: 4, maxWidth: '1400px', mx: 'auto', width: '100%' }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <Typography 
                variant="h3" 
                gutterBottom 
                sx={{ 
                  mb: 2,
                  fontFamily: 'source-code-pro, monospace',
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}
              >
                Welcome back! 👋
                <DiscordButton />
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
                Continue your learning journey with interactive stories.
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Box sx={{ 
                    textAlign: 'center', 
                    p: 2, 
                    bgcolor: '#FF4F00',
                    color: 'white',
                    borderRadius: 2,
                    transition: 'all 0.3s ease',
                    boxShadow: '0 4px 12px rgba(255,79,0,0.2)',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      boxShadow: '0 8px 16px rgba(255,79,0,0.3)',
                    }
                  }}>
                    <Typography variant="h4" sx={{ 
                      fontWeight: 600,
                      color: 'white'
                    }}>{storiesCompleted}</Typography>
                    <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.9)' }}>Stories Completed</Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box sx={{ 
                    textAlign: 'center', 
                    p: 2, 
                    bgcolor: '#2196F3',
                    color: 'white',
                    borderRadius: 2,
                    transition: 'all 0.3s ease',
                    boxShadow: '0 4px 12px rgba(33,150,243,0.2)',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      boxShadow: '0 8px 16px rgba(33,150,243,0.3)',
                    }
                  }}>
                    <Typography variant="h4" sx={{ 
                      fontWeight: 600,
                      color: 'white'
                    }}>{activeClasses}</Typography>
                    <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.9)' }}>Active Classes</Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box sx={{ 
                    textAlign: 'center', 
                    p: 2, 
                    bgcolor: '#00C853',
                    color: 'white',
                    borderRadius: 2,
                    transition: 'all 0.3s ease',
                    boxShadow: '0 4px 12px rgba(0,200,83,0.2)',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      boxShadow: '0 8px 16px rgba(0,200,83,0.3)',
                    }
                  }}>
                    <Typography variant="h4" sx={{ 
                      fontWeight: 600,
                      color: 'white'
                    }}>{learningStreak}</Typography>
                    <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.9)' }}>Day Streak</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              {lastReadStory && (
                <StyledCard 
                  sx={{ 
                    height: '100%', 
                    display: 'flex', 
                    flexDirection: 'column',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      boxShadow: '0 8px 24px rgba(0,0,0,0.15)',
                    },
                    position: 'relative',
                    overflow: 'hidden',
                    minHeight: '180px',
                  }}
                >
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="overline" color="text.secondary">
                      Continue Reading
                    </Typography>
                    <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
                      {lastReadStory.title}
                    </Typography>
                    <Box sx={{ mb: 2 }}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                        <Typography variant="caption" color="text.secondary">
                          Progress
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          Scene {lastReadStory.current_node?.scene_number || 1} of 8
                        </Typography>
                      </Box>
                      <Box sx={{ 
                        display: 'flex', 
                        gap: '4px',
                        width: '100%',
                        mb: 1
                      }}>
                        {[...Array(8)].map((_, index) => (
                          <Box
                            key={index}
                            sx={{
                              flex: 1,
                              height: 6,
                              borderRadius: 3,
                              bgcolor: 'rgba(255,255,255,0.2)',
                              position: 'relative',
                              overflow: 'hidden',
                              '&::after': {
                                content: '""',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                bgcolor: index < ((lastReadStory.current_node?.scene_number || 1) - 1)
                                  ? theme.palette.mode === 'dark'
                                    ? '#FF4F00'  
                                    : '#FF4F00'  
                                  : 'transparent',
                                borderRadius: 'inherit',
                              }
                            }}
                          />
                        ))}
                      </Box>
                      <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
                        {!lastReadStory.current_node?.scene_number ? 'Not started' :
                         lastReadStory.current_node.scene_number === 1 ? 'Just started' : 
                         lastReadStory.current_node.scene_number === 8 ? 'Almost done!' :
                         lastReadStory.current_node.scene_number === 2 ? 'Recently started' :
                         `Scene ${lastReadStory.current_node.scene_number} of 8`}
                      </Typography>
                    </Box>
                    <Button
                      variant="contained"
                      onClick={() => handleAction(`/story/${lastReadStory.id}`)}
                      fullWidth
                      sx={{
                        py: 1,
                        borderRadius: 2,
                        background: 'linear-gradient(45deg, #FF8A00, #FF4F00)',
                        color: "white",
                        fontWeight: 600,
                        boxShadow: "0 4px 15px rgba(255, 138, 0, 0.3)",
                        transition: "all 0.3s ease",
                        "&:hover": {
                          background: "linear-gradient(45deg, #FF9500, #FF5F00)",
                          boxShadow: "0 6px 20px rgba(255, 138, 0, 0.4)",
                          transform: "translateY(-2px)",
                        },
                        "&:disabled": {
                          background: "rgba(255, 138, 0, 0.5)",
                          color: "#fff",
                        },
                      }}
                    >
                      Continue Reading
                    </Button>
                  </CardContent>
                </StyledCard>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Continue Journey Section */}
      <Box sx={{ mb: 4, maxWidth: 'lg', mx: 'auto', width: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            Continue Journey
          </Typography>
          <Button
            component={RouterLink}
            to="/library"
            color="primary"
            sx={{ textTransform: 'none' }}
          >
            View All
          </Button>
        </Box>
        <Grid container spacing={3}>
          {myStories.slice(0, 8).map((story) => (
            <Grid item xs={12} sm={6} md={3} key={story.id}>
              <StyledCard
                onClick={() => handleAction(`/story/${story.id}`)}
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'all 0.3s ease',
                  cursor: 'pointer',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: '0 8px 24px rgba(0,0,0,0.15)',
                  },
                  position: 'relative',
                  overflow: 'hidden',
                  minHeight: '180px',
                }}
              >
                {/* Background Image with Gradient Overlay */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    height: '100%',
                    background: `linear-gradient(180deg, 
                      rgba(0,0,0,0) 0%, 
                      rgba(0,0,0,0.7) 50%, 
                      rgba(0,0,0,0.9) 100%
                    )`,
                    zIndex: 1,
                  }}
                />
                <Box
                  component="img"
                  src={story.current_node?.image_url || '/static/images/fallback_cover.png'}
                  alt={story.title}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
                
                {/* Content */}
                <CardContent 
                  sx={{ 
                    position: 'relative', 
                    zIndex: 2,
                    mt: 'auto',
                    color: 'white',
                    p: 1.5,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    '&:last-child': { pb: 1.5 }, 
                  }}
                >
                  <Box sx={{ flex: 1 }}>
                    <Typography 
                      variant="caption" 
                      sx={{ 
                        color: 'rgba(255,255,255,0.7)',
                        display: 'block',
                        mb: 0.25,
                        fontSize: '0.7rem',
                      }}
                    >
                      {story.current_node?.scene_number === 1 ? 'Just started' : 
                       story.current_node.scene_number === 8 ? 'Almost done!' :
                       story.current_node.scene_number === 2 ? 'Recently started' :
                       `Scene ${story.current_node.scene_number} of 8`}
                    </Typography>
                    <Typography 
                      variant="h6" 
                      sx={{ 
                        fontWeight: 600,
                        textShadow: '0 2px 4px rgba(0,0,0,0.2)',
                        mb: 0.5,
                        fontSize: '1rem',
                        lineHeight: 1.2,
                      }}
                    >
                      {story.title}
                    </Typography>
                  </Box>

                  {/* Progress Bar */}
                  <Box sx={{ mt: 'auto' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.25 }}>
                      <Typography variant="caption" color="text.secondary" sx={{ mt: 1 }}>
                        Progress
                      </Typography>
                      <Typography variant="caption" color="text.secondary" sx={{ mt: 1 }}>
                        Scene {story.current_node?.scene_number || 1} of 8
                      </Typography>
                    </Box>
                    <Box sx={{ 
                      display: 'flex', 
                      gap: '4px',
                      width: '100%',
                    }}>
                      {[...Array(8)].map((_, index) => (
                        <Box
                          key={index}
                          sx={{
                            flex: 1,
                            height: 6,
                            borderRadius: 3,
                            bgcolor: 'rgba(255,255,255,0.2)',
                            position: 'relative',
                            overflow: 'hidden',
                            '&::after': {
                              content: '""',
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              bgcolor: index < (story.current_node?.scene_number || 0)
                                ? theme.palette.mode === 'dark'
                                  ? '#FF4F00'  
                                  : '#FF4F00'  
                                : 'transparent',
                              borderRadius: 'inherit',
                            }
                          }}
                        />
                      ))}
                    </Box>
                  </Box>
                </CardContent>
              </StyledCard>
            </Grid>
          ))}
          {myStories.length === 0 && (
            <Grid item xs={12}>
              <StyledPaper
                sx={{
                  p: 3,
                  textAlign: 'center',
                  position: 'relative',
                  overflow: 'hidden',
                }}
              >
                <Box sx={{ py: 2 }}>
                  <Typography variant="h6" gutterBottom sx={{ color: 'text.primary' }}>
                    No stories in progress
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2 }}>
                    Start a new story to begin your journey!
                  </Typography>
                  <Button
                    component={RouterLink}
                    to="/stories"
                    variant="contained"
                    sx={{
                      bgcolor: theme.palette.mode === 'dark' ? '#FF4F00' : '#FF4F00',
                      color: 'white',
                      '&:hover': {
                        bgcolor: theme.palette.mode === 'dark' ? '#FF6000' : '#FF6000',
                      },
                    }}
                  >
                    Explore Stories
                  </Button>
                </Box>
              </StyledPaper>
            </Grid>
          )}
        </Grid>
      </Box>

      {/* Popular Classes */}
      {renderClassGrid(popularClasses, "Popular Classes")}

      {/* Explore Stories */}
      <Box sx={{ py: 4 }}>
        <Grid container spacing={3} sx={{ maxWidth: "1400px", mx: "auto", mb: 4 }}>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              placeholder="Search stories"
              value={searchQuery}
              onChange={(e) => handleSearch(e.target.value)}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  borderRadius: 2,
                  bgcolor: theme.palette.background.paper,
                  boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
                  transition: "all 0.3s ease",
                  "&:hover": {
                    boxShadow: "0 6px 15px rgba(0,0,0,0.15)",
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl sx={{ width: "100%" }}>
              <Select
                value={sortBy}
                onChange={(e) => handleSortChange(e.target.value)}
                displayEmpty
                renderValue={(selected) => {
                  if (selected === "popular") {
                    return "Sort by popularity";
                  } else if (selected === "recent") {
                    return "Sort by date";
                  } else if (selected === "rated") {
                    return "Sort by rating";
                  } else {
                    return "Sort by";
                  }
                }}
                sx={{
                  bgcolor: theme.palette.background.paper,
                  borderRadius: 2,
                  boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
                  "&:hover": {
                    boxShadow: "0 6px 15px rgba(0,0,0,0.15)",
                  },
                }}
              >
                <MenuItem value="popular">Sort by popularity</MenuItem>
                <MenuItem value="recent">Sort by date</MenuItem>
                <MenuItem value="rated">Sort by rating</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {renderStoryGrid(sortStories(filterStories(exploreStories)), "Explore Stories")}
      </Box>

      {/* My Classes */}
      {renderClassGrid(myClasses, "My Classes")}

      {/* My Stories */}
      <Box sx={{ mb: 4, maxWidth: 'lg', mx: 'auto', width: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            My Stories
          </Typography>
          <Button
            component={RouterLink}
            to="/library"
            color="primary"
            sx={{ textTransform: 'none' }}
          >
            View All
          </Button>
        </Box>
        <Grid container spacing={3}>
          {myStories.slice(0, 8).map((story) => (
            <Grid item xs={12} sm={6} md={3} key={story.id}>
              <StyledCard
                onClick={() => handleAction(`/story/${story.id}`)}
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'all 0.3s ease',
                  cursor: 'pointer',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: '0 8px 24px rgba(0,0,0,0.15)',
                  },
                  position: 'relative',
                  overflow: 'hidden',
                  minHeight: '180px',
                }}
              >
                {/* Background Image with Gradient Overlay */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    height: '100%',
                    background: `linear-gradient(180deg, 
                      rgba(0,0,0,0) 0%, 
                      rgba(0,0,0,0.7) 50%, 
                      rgba(0,0,0,0.9) 100%
                    )`,
                    zIndex: 1,
                  }}
                />
                <Box
                  component="img"
                  src={story.current_node?.image_url || '/static/images/fallback_cover.png'}
                  alt={story.title}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
                
                {/* Content */}
                <CardContent 
                  sx={{ 
                    position: 'relative', 
                    zIndex: 2,
                    mt: 'auto',
                    color: 'white',
                    p: 1.5,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    '&:last-child': { pb: 1.5 }, 
                  }}
                >
                  <Box sx={{ flex: 1 }}>
                    <Typography 
                      variant="caption" 
                      sx={{ 
                        color: 'rgba(255,255,255,0.7)',
                        display: 'block',
                        mb: 0.25,
                        fontSize: '0.7rem',
                      }}
                    >
                      {story.current_node?.scene_number === 1 ? 'Just started' : 
                       story.current_node.scene_number === 8 ? 'Almost done!' :
                       story.current_node.scene_number === 2 ? 'Recently started' :
                       `Scene ${story.current_node.scene_number} of 8`}
                    </Typography>
                    <Typography 
                      variant="h6" 
                      sx={{ 
                        fontWeight: 600,
                        textShadow: '0 2px 4px rgba(0,0,0,0.2)',
                        mb: 0.5,
                        fontSize: '1rem',
                        lineHeight: 1.2,
                      }}
                    >
                      {story.title}
                    </Typography>
                  </Box>

                  {/* Segmented Progress Bar */}
                  <Box sx={{ mt: 'auto' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.25 }}>
                      <Typography variant="caption" color="text.secondary" sx={{ mt: 1 }}>
                        Progress
                      </Typography>
                      <Typography variant="caption" color="text.secondary" sx={{ mt: 1 }}>
                        Scene {story.current_node?.scene_number || 1} of 8
                      </Typography>
                    </Box>
                    <Box sx={{ 
                      display: 'flex', 
                      gap: '4px',
                      width: '100%',
                    }}>
                      {[...Array(8)].map((_, index) => (
                        <Box
                          key={index}
                          sx={{
                            flex: 1,
                            height: 6,
                            borderRadius: 3,
                            bgcolor: 'rgba(255,255,255,0.2)',
                            position: 'relative',
                            overflow: 'hidden',
                            '&::after': {
                              content: '""',
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              bgcolor: index < (story.current_node?.scene_number || 0)
                                ? theme.palette.mode === 'dark'
                                  ? '#FF4F00'  
                                  : '#FF4F00'  
                                : 'transparent',
                              borderRadius: 'inherit',
                            }
                          }}
                        />
                      ))}
                    </Box>
                  </Box>
                </CardContent>
              </StyledCard>
            </Grid>
          ))}
          {myStories.length === 0 && (
            <Grid item xs={12}>
              <StyledPaper
                sx={{
                  p: 3,
                  textAlign: 'center',
                  position: 'relative',
                  overflow: 'hidden',
                }}
              >
                <Box sx={{ py: 2 }}>
                  <Typography variant="h6" gutterBottom sx={{ color: 'text.primary' }}>
                    No stories in progress
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2 }}>
                    Start a new story to begin your journey!
                  </Typography>
                  <Button
                    component={RouterLink}
                    to="/stories"
                    variant="contained"
                    sx={{
                      bgcolor: theme.palette.mode === 'dark' ? '#FF4F00' : '#FF4F00',
                      color: 'white',
                      '&:hover': {
                        bgcolor: theme.palette.mode === 'dark' ? '#FF6000' : '#FF6000',
                      },
                    }}
                  >
                    Explore Stories
                  </Button>
                </Box>
              </StyledPaper>
            </Grid>
          )}
        </Grid>
      </Box>
    </DashboardContainer>
  );
};

export default Dashboard;