import React from 'react';
import storybyteIcon from '../assets/Asset 4.png';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Menu,
  MenuItem,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Collapse,
  useMediaQuery,
  useTheme,
  Tooltip,
  Slide,
  useScrollTrigger,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  Menu as MenuIcon,
  ChevronRight as ChevronRightIcon,
  Brightness4 as DarkModeIcon,
  Brightness7 as LightModeIcon,
} from '@mui/icons-material';
import { ColorModeContext } from '../App';

interface NavbarProps {
  isAuthenticated: boolean;
  onLogout: () => void;
  isTransparent?: boolean;
}

interface Props {
  window?: () => Window;
  children: React.ReactElement;
}

function HideOnScroll(props: Props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    threshold: 100,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const Navbar: React.FC<NavbarProps> = ({ isAuthenticated, onLogout, isTransparent = false }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [openSubMenu, setOpenSubMenu] = React.useState<string | null>(null);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleSubMenuToggle = (menu: string) => {
    setOpenSubMenu(openSubMenu === menu ? null : menu);
  };

  const handleNavigation = (path: string) => () => {
    navigate(path);
    setDrawerOpen(false);
    setOpenSubMenu(null);
  };

  const navButtonStyle = {
    color: theme.palette.mode === 'dark' ? 'white' : 'black',
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)',
    },
    textTransform: 'none',
    fontWeight: 500,
    ...(isTransparent && {
      textShadow: '0 2px 8px rgba(0,0,0,0.5)',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: theme.palette.mode === 'dark' 
          ? 'radial-gradient(circle at center, rgba(0,0,0,0.1) 0%, transparent 70%)'
          : 'radial-gradient(circle at center, rgba(255,255,255,0.1) 0%, transparent 70%)',
        filter: 'blur(4px)',
        zIndex: -1
      }
    })
  };

  const menuItemStyle = {
    minWidth: '180px', 
    padding: '10px 20px',
    color: theme.palette.text.primary,
    '&:hover': {
      bgcolor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.04)'
    }
  };

  const MobileDrawer = () => (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={handleDrawerToggle}
      PaperProps={{
        sx: {
          width: 280,
          background: theme.palette.mode === 'dark'
            ? 'rgba(18, 18, 18, 0.8)'
            : 'rgba(255, 255, 255, 0.8)',
          backdropFilter: 'blur(10px)',
          borderLeft: '1px solid',
          borderColor: theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, 0.1)'
            : 'rgba(0, 0, 0, 0.1)',
        },
      }}
    >
      <List sx={{ color: 'inherit' }}>
        <ListItem button onClick={handleNavigation('/about')}>
          <ListItemText primary="About" />
        </ListItem>

        {isAuthenticated ? (
          <>
            <ListItem button onClick={handleNavigation('/public')}>
              <ListItemText primary="Public Feed" />
            </ListItem>

            <ListItem button onClick={() => handleSubMenuToggle('library')}>
              <ListItemText primary="Library" />
              <ChevronRightIcon
                sx={{
                  transform: openSubMenu === 'library' ? 'rotate(90deg)' : 'none',
                  transition: 'transform 0.3s',
                }}
              />
            </ListItem>
            <Collapse in={openSubMenu === 'library'} timeout="auto">
              <List component="div" disablePadding>
                <ListItem button sx={{ pl: 4 }} onClick={handleNavigation('/library')}>
                  <ListItemText primary="Public Library" />
                </ListItem>
                <ListItem button sx={{ pl: 4 }} onClick={handleNavigation('/my-library')}>
                  <ListItemText primary="My Library" />
                </ListItem>
              </List>
            </Collapse>

            <ListItem button onClick={handleNavigation('/classes')}>
              <ListItemText primary="Classes" />
            </ListItem>

            <ListItem button onClick={handleNavigation('/profile')}>
              <ListItemText primary="Profile" />
            </ListItem>

            <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Button
                variant="contained"
                fullWidth
                onClick={handleNavigation('/classes/enroll')}
                sx={{
                  background: 'linear-gradient(45deg, #2196F3, #1976D2)',
                  color: 'white',
                  py: 1,
                  borderRadius: 2,
                  textTransform: 'none',
                  fontWeight: 600,
                }}
              >
                Join Class
              </Button>

              <Button
                variant="contained"
                fullWidth
                onClick={handleNavigation('/new')}
                sx={{
                  background: 'linear-gradient(45deg, #FF8A00, #FF4F00)',
                  color: 'white',
                  py: 1,
                  borderRadius: 2,
                  textTransform: 'none',
                  fontWeight: 600,
                }}
              >
                New Story
              </Button>
            </Box>
          </>
        ) : (
          <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Button
              component={RouterLink}
              to="/login"
              sx={{
                background: 'linear-gradient(45deg, #2196F3, #1976D2)',
                color: 'white',
                px: 3,
                py: 1,
                borderRadius: 2,
                textTransform: 'none',
                fontWeight: 600,
                fontSize: '1rem',
                boxShadow: '0 4px 15px rgba(33, 150, 243, 0.3)',
                backdropFilter: 'blur(10px)',
                border: 'none',
                transition: 'all 0.3s ease',
                '&:hover': {
                  background: 'linear-gradient(45deg, #42A5F5, #1E88E5)',
                  boxShadow: '0 6px 20px rgba(33, 150, 243, 0.4)',
                  transform: 'translateY(-2px)',
                },
                '&:active': {
                  transform: 'translateY(1px)',
                  boxShadow: '0 2px 10px rgba(33, 150, 243, 0.2)',
                },
                ...(isTransparent && {
                  textShadow: '0 2px 8px rgba(0,0,0,0.5)',
                  boxShadow: '0 4px 15px rgba(0,0,0,0.3), 0 4px 15px rgba(33, 150, 243, 0.3)',
                  '&:hover': {
                    boxShadow: '0 6px 20px rgba(0,0,0,0.4), 0 6px 20px rgba(33, 150, 243, 0.4)',
                    transform: 'translateY(-2px)',
                  }
                })
              }}
            >
              Login
            </Button>
            <Button
              variant="contained"
              onClick={handleNavigation('/signup')}
              sx={{
                background: 'linear-gradient(45deg, #FF8A00, #FF4F00)',
                color: 'white',
                px: 3,
                py: 1,
                borderRadius: 2,
                textTransform: 'none',
                fontWeight: 600,
                fontSize: '1rem',
                boxShadow: '0 4px 15px rgba(255, 138, 0, 0.3)',
                border: 'none',
                transition: 'all 0.3s ease',
                '&:hover': {
                  background: 'linear-gradient(45deg, #FF9500, #FF5500)',
                  boxShadow: '0 6px 20px rgba(255, 138, 0, 0.4)',
                  transform: 'translateY(-2px)',
                },
                '&:active': {
                  transform: 'translateY(1px)',
                  boxShadow: '0 2px 10px rgba(255, 138, 0, 0.2)',
                }
              }}
            >
              Sign Up
            </Button>
          </Box>
        )}
      </List>
    </Drawer>
  );

  const DesktopNav = () => {
    if (!isAuthenticated) {
      return (
        <>
          <Button
            component={RouterLink}
            to="/login"
            sx={{
              background: 'linear-gradient(45deg, #2196F3, #1976D2)',
              color: 'white',
              px: 3,
              py: 1,
              borderRadius: 2,
              textTransform: 'none',
              fontWeight: 600,
              fontSize: '1rem',
              boxShadow: '0 4px 15px rgba(33, 150, 243, 0.3)',
              backdropFilter: 'blur(10px)',
              border: 'none',
              transition: 'all 0.3s ease',
              '&:hover': {
                background: 'linear-gradient(45deg, #42A5F5, #1E88E5)',
                boxShadow: '0 6px 20px rgba(33, 150, 243, 0.4)',
                transform: 'translateY(-2px)',
              },
              '&:active': {
                transform: 'translateY(1px)',
                boxShadow: '0 2px 10px rgba(33, 150, 243, 0.2)',
              }
            }}
          >
            Login
          </Button>
          <Button
            variant="contained"
            onClick={() => navigate('/signup')}
            sx={{
              background: 'linear-gradient(45deg, #FF8A00, #FF4F00)',
              color: 'white',
              px: 3,
              py: 1,
              borderRadius: 2,
              textTransform: 'none',
              fontWeight: 600,
              fontSize: '1rem',
              boxShadow: '0 4px 15px rgba(255, 138, 0, 0.3)',
              border: 'none',
              transition: 'all 0.3s ease',
              '&:hover': {
                background: 'linear-gradient(45deg, #FF9500, #FF5500)',
                boxShadow: '0 6px 20px rgba(255, 138, 0, 0.4)',
                transform: 'translateY(-2px)',
              },
              '&:active': {
                transform: 'translateY(1px)',
                boxShadow: '0 2px 10px rgba(255, 138, 0, 0.2)',
              }
            }}
          >
            Sign Up
          </Button>
        </>
      );
    }

    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Button
          component={RouterLink}
          to="/about"
          sx={navButtonStyle}
        >
          About
        </Button>
        <Button
          component={RouterLink}
          to="/public"
          sx={navButtonStyle}
        >
          Public Feed
        </Button>
        <Button
          component={RouterLink}
          to="/library"
          sx={navButtonStyle}
        >
          Library
        </Button>
        <Button
          component={RouterLink}
          to="/classes"
          sx={navButtonStyle}
        >
          Classes
        </Button>
        <Button
          component={RouterLink}
          to="/profile"
          sx={navButtonStyle}
        >
          Profile
        </Button>
        <Tooltip title={`Toggle ${theme.palette.mode === 'dark' ? 'light' : 'dark'} mode`}>
          <IconButton
            onClick={colorMode.toggleColorMode}
            sx={{
              color: 'inherit',
              mr: 1,
            }}
          >
            {theme.palette.mode === 'dark' ? <LightModeIcon /> : <DarkModeIcon />}
          </IconButton>
        </Tooltip>
        <Button
          variant="contained"
          onClick={() => navigate('/classes')}
          sx={{
            background: theme.palette.mode === 'dark' 
              ? 'linear-gradient(45deg, #2196F3, #1976D2)'
              : '#2196F3',
            color: 'white',
            px: 3,
            py: 1,
            mr: 1,
            borderRadius: 2,
            textTransform: 'none',
            fontWeight: 600,
            border: 'none',
            transition: 'all 0.3s ease',
            '&:hover': {
              background: theme.palette.mode === 'dark'
                ? 'linear-gradient(45deg, #1E88E5, #1565C0)'
                : '#1E88E5',
              transform: 'translateY(-2px)',
            }
          }}
        >
          Join Class
        </Button>
        <Button
          variant="contained"
          onClick={() => navigate('/new')}
          sx={{
            background: theme.palette.mode === 'dark' 
              ? 'linear-gradient(45deg, #FF8A00, #FF4F00)'
              : '#FF4F00',
            color: 'white',
            px: 3,
            py: 1,
            borderRadius: 2,
            textTransform: 'none',
            fontWeight: 600,
            border: 'none',
            transition: 'all 0.3s ease',
            '&:hover': {
              background: theme.palette.mode === 'dark'
                ? 'linear-gradient(45deg, #FF9500, #FF6000)'
                : '#FF6000',
              transform: 'translateY(-2px)',
            }
          }}
        >
          New Story
        </Button>
      </Box>
    );
  };

  return (
    <HideOnScroll>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          background: 'none',
          boxShadow: 'none',
          backdropFilter: 'none',
          borderBottom: 'none',
          transition: 'transform 0.2s ease-in-out',
          '& .MuiToolbar-root': {
            color: theme.palette.mode === 'dark' ? 'white' : 'black',
          }
        }}
      >
        <Toolbar>
          <Box
            component={RouterLink}
            to="/"
            sx={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              color: 'inherit',
            }}
          >
            <img
              src={storybyteIcon}
              alt="Storybyte Logo"
              style={{ height: '40px', marginRight: '10px' }}
            />
            <Typography 
              variant="h6" 
              component="div" 
              sx={{ 
                flexGrow: 1, 
                fontWeight: 'bold', 
                letterSpacing: '.1rem',
                display: { xs: 'none', sm: 'block' },
                fontFamily: 'source-code-pro, monospace'
              }}
            >
              Storybyte
            </Typography>
          </Box>

          <Box sx={{ flexGrow: 1 }} />

          {isMobile ? (
            <>
              <Tooltip title={`Toggle ${theme.palette.mode === 'dark' ? 'light' : 'dark'} mode`}>
                <IconButton
                  onClick={colorMode.toggleColorMode}
                  sx={{
                    color: 'inherit',
                    mr: 1,
                  }}
                >
                  {theme.palette.mode === 'dark' ? <LightModeIcon /> : <DarkModeIcon />}
                </IconButton>
              </Tooltip>

              <IconButton
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
              <MobileDrawer />
            </>
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {isAuthenticated ? (
                <DesktopNav />
              ) : (
                <>
                  <Button
                    component={RouterLink}
                    to="/about"
                    sx={navButtonStyle}
                  >
                    About
                  </Button>
                  <Button
                    component={RouterLink}
                    to="/login"
                    sx={{
                      background: 'linear-gradient(45deg, #2196F3, #1976D2)',
                      color: 'white',
                      px: 3,
                      py: 1,
                      borderRadius: 2,
                      textTransform: 'none',
                      fontWeight: 600,
                      fontSize: '1rem',
                      boxShadow: '0 4px 15px rgba(33, 150, 243, 0.3)',
                      backdropFilter: 'blur(10px)',
                      border: 'none',
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        background: 'linear-gradient(45deg, #42A5F5, #1E88E5)',
                        boxShadow: '0 6px 20px rgba(33, 150, 243, 0.4)',
                        transform: 'translateY(-2px)',
                      },
                      '&:active': {
                        transform: 'translateY(1px)',
                        boxShadow: '0 2px 10px rgba(33, 150, 243, 0.2)',
                      }
                    }}
                  >
                    Login
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => navigate('/signup')}
                    sx={{
                      background: 'linear-gradient(45deg, #FF8A00, #FF4F00)',
                      color: 'white',
                      px: 3,
                      py: 1,
                      borderRadius: 2,
                      textTransform: 'none',
                      fontWeight: 600,
                      fontSize: '1rem',
                      boxShadow: '0 4px 15px rgba(255, 138, 0, 0.3)',
                      border: 'none',
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        background: 'linear-gradient(45deg, #FF9500, #FF5500)',
                        boxShadow: '0 6px 20px rgba(255, 138, 0, 0.4)',
                        transform: 'translateY(-2px)',
                      },
                      '&:active': {
                        transform: 'translateY(1px)',
                        boxShadow: '0 2px 10px rgba(255, 138, 0, 0.2)',
                      }
                    }}
                  >
                    Sign Up
                  </Button>
                </>
              )}
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
};

export default Navbar;