import { styled, alpha } from '@mui/material/styles';
import { Box } from '@mui/material';

export const GradientBackground = styled(Box)(({ theme }) => ({
  background: theme.palette.mode === 'dark'
    ? `linear-gradient(135deg, 
        ${alpha('#1a237e', 0.2)} 0%, 
        ${alpha('#0d47a1', 0.2)} 50%,
        ${alpha('#e65100', 0.15)} 100%)`
    : `linear-gradient(135deg, 
        ${alpha('#e3f2fd', 0.8)} 0%, 
        ${alpha('#bbdefb', 0.8)} 50%,
        ${alpha('#ffe0b2', 0.8)} 100%)`,
  minHeight: '100vh',
  minWidth: '100vw',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: -1,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `radial-gradient(circle at top right, 
      ${alpha(theme.palette.mode === 'dark' ? '#ff9800' : '#ff9800', 0.08)} 0%, 
      transparent 70%)`,
    pointerEvents: 'none',
  },
}));
