import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { 
  Box, Typography, Grid, Card, CardContent, CardActionArea, CardMedia,
  CircularProgress, Alert, Button, Chip, Snackbar, Container,
  useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { PlayArrow, Favorite } from '@mui/icons-material';
import axios from 'axios';

interface Story {
  id: number;
  title: string;
  context: string;
  plays: number;
  likes: number;
  cover_image?: string;
  current_node?: {
    image_url?: string;
    scene_number?: number;
  };
}

interface ClassDetails {
  id: number;
  name: string;
  description: string;
  class_code: string;
  is_public: boolean;
  owner: string;
  students_count: number;
  stories: Story[];
}

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';
const S3_BUCKET_URL = 'https://storybyte1.s3.amazonaws.com';
// Use a simple placeholder image as fallback
const FALLBACK_IMAGE = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAwIiBoZWlnaHQ9IjMwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cmVjdCB3aWR0aD0iNDAwIiBoZWlnaHQ9IjMwMCIgZmlsbD0iI2VlZSIvPjx0ZXh0IHg9IjUwJSIgeT0iNTAlIiBmb250LWZhbWlseT0iQXJpYWwiIGZvbnQtc2l6ZT0iMjQiIGZpbGw9IiM5OTkiIHRleHQtYW5jaG9yPSJtaWRkbGUiIGRvbWluYW50LWJhc2VsaW5lPSJtaWRkbGUiPk5vIGltYWdlIGF2YWlsYWJsZTwvdGV4dD48L3N2Zz4=';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.spacing(2),
  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
  }
}));

const StyledCardMedia = styled(CardMedia)(() => ({
  height: 200,
  backgroundSize: 'cover',
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '50%',
    background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%)',
  }
}));

const ClassDetail: React.FC = () => {
  const theme = useTheme();
  const { classId } = useParams<{ classId: string }>();
  const navigate = useNavigate();
  const [classDetails, setClassDetails] = useState<ClassDetails | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' as 'success' | 'error' });

  const getImageUrl = useCallback((story: Story): string => {
    // Check for valid story cover image
    if (story.cover_image) {
      if (story.cover_image.startsWith('http')) return story.cover_image;
      if (story.cover_image.startsWith('data:')) return story.cover_image;
      return `${S3_BUCKET_URL}/${story.cover_image}`;
    }

    // Check for valid story node image
    if (story.current_node?.image_url) {
      if (story.current_node.image_url.startsWith('http')) return story.current_node.image_url;
      if (story.current_node.image_url.startsWith('data:')) return story.current_node.image_url;
      return `${S3_BUCKET_URL}/${story.current_node.image_url}`;
    }

    // Return embedded fallback image if no valid image is found
    return FALLBACK_IMAGE;
  }, []);

  const fetchClassDetails = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get<ClassDetails>(`${API_BASE_URL}/classes/${classId}/`);
      setClassDetails(response.data);
    } catch (error) {
      console.error('Error fetching class details:', error);
      setError('Failed to fetch class details. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [classId]);

  useEffect(() => {
    fetchClassDetails();
  }, [fetchClassDetails]);

  const handleEnroll = async () => {
    try {
      await axios.post(`${API_BASE_URL}/classes/${classId}/enroll/`);
      setSnackbar({ open: true, message: 'Enrolled successfully!', severity: 'success' });
      fetchClassDetails();
    } catch (error) {
      console.error('Error enrolling in class:', error);
      setSnackbar({ open: true, message: 'Failed to enroll. Please try again.', severity: 'error' });
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error || !classDetails) {
    return (
      <Box sx={{ mt: 4 }}>
        <Alert severity="error">{error || 'Class not found'}</Alert>
        <Button component={Link} to="/classes" sx={{ mt: 2 }}>
          Back to Classes
        </Button>
      </Box>
    );
  }

  return (
    <Box sx={{ pt: 10 }}>
      <Container maxWidth="lg">
        <Button 
          onClick={() => navigate(-1)} 
          sx={{ 
            mb: 2,
            color: 'text.primary',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.1)'
            }
          }}
        >
          Back to Classes
        </Button>
        
        <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 600 }}>
          {classDetails.name}
        </Typography>
        
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3, gap: 2 }}>
          <Chip 
            label={classDetails.is_public ? "Public" : "Private"} 
            color={classDetails.is_public ? "success" : "default"}
            sx={{ borderRadius: '8px' }}
          />
          <Typography variant="body1" sx={{ 
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            padding: '8px 16px',
            borderRadius: '8px',
            fontFamily: 'monospace'
          }}>
            Class Code: {classDetails.class_code}
          </Typography>
          <Button 
            variant="contained" 
            onClick={handleEnroll}
            sx={{ 
              ml: 'auto',
              borderRadius: '8px',
              background: 'linear-gradient(45deg, #FF8A00, #FF4F00)',
              '&:hover': {
                background: 'linear-gradient(45deg, #FF9500, #FF6000)',
              }
            }}
          >
            Enroll
          </Button>
        </Box>

        <Typography variant="body1" sx={{ mb: 3, color: 'text.secondary' }}>
          {classDetails.description}
        </Typography>

        <Box sx={{ 
          mb: 4, 
          p: 2, 
          borderRadius: '12px',
          backgroundColor: 'rgba(255, 255, 255, 0.05)',
          display: 'flex',
          gap: 4
        }}>
          <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
            Owner: <span style={{ color: 'text.primary', fontWeight: 500 }}>{classDetails.owner}</span>
          </Typography>
          <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
            Students: <span style={{ color: 'text.primary', fontWeight: 500 }}>{classDetails.students_count}</span>
          </Typography>
        </Box>

        <Typography variant="h5" component="h2" gutterBottom sx={{ fontWeight: 600, mb: 3 }}>
          Stories in this Class
        </Typography>

        {classDetails.stories.length === 0 ? (
          <Box sx={{ 
            p: 4, 
            textAlign: 'center',
            borderRadius: '12px',
            backgroundColor: 'rgba(255, 255, 255, 0.05)'
          }}>
            <Typography variant="h6" sx={{ color: 'text.secondary', mb: 2 }}>
              No stories found in this class
            </Typography>
            <Button 
              variant="contained"
              component={Link}
              to="/new"
              sx={{ 
                borderRadius: '8px',
                background: 'linear-gradient(45deg, #FF8A00, #FF4F00)',
                '&:hover': {
                  background: 'linear-gradient(45deg, #FF9500, #FF6000)',
                }
              }}
            >
              Create First Story
            </Button>
          </Box>
        ) : (
          <Grid container spacing={3}>
            {classDetails.stories.map((story) => (
              <Grid item xs={12} sm={6} md={4} key={story.id}>
                <StyledCard
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      boxShadow: '0 8px 24px rgba(0,0,0,0.15)',
                    },
                    position: 'relative',
                    overflow: 'hidden',
                    minHeight: '180px',
                  }}
                >
                  {/* Background Image with Gradient Overlay */}
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      height: '100%',
                      background: `linear-gradient(180deg, 
                        rgba(0,0,0,0) 0%, 
                        rgba(0,0,0,0.7) 50%, 
                        rgba(0,0,0,0.9) 100%
                      )`,
                      zIndex: 1,
                    }}
                  />
                  <Box
                    component="img"
                    src={story.current_node?.image_url || '/static/images/fallback_cover.png'}
                    alt={story.title}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                  
                  {/* Content */}
                  <CardActionArea
                    component={Link}
                    to={`/story/${story.id}`}
                    sx={{ height: '100%' }}
                  >
                    <CardContent 
                      sx={{ 
                        position: 'relative', 
                        zIndex: 2,
                        mt: 'auto',
                        color: 'white',
                        p: 1.5,
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        '&:last-child': { pb: 1.5 }, 
                      }}
                    >
                      <Box sx={{ flex: 1 }}>
                        <Typography 
                          variant="caption" 
                          sx={{ 
                            color: 'rgba(255,255,255,0.7)',
                            display: 'block',
                            mb: 0.25,
                            fontSize: '0.7rem',
                          }}
                        >
                          {!story.current_node?.scene_number ? 'Not started' :
                           story.current_node.scene_number === 1 ? 'Just started' : 
                           story.current_node.scene_number === 8 ? 'Almost done!' :
                           story.current_node.scene_number === 2 ? 'Recently started' :
                           `Scene ${story.current_node.scene_number} of 8`}
                        </Typography>
                        <Typography 
                          variant="h6" 
                          sx={{ 
                            fontWeight: 600,
                            textShadow: '0 2px 4px rgba(0,0,0,0.2)',
                            mb: 0.5,
                            fontSize: '1rem',
                            lineHeight: 1.2,
                          }}
                        >
                          {story.title}
                        </Typography>
                      </Box>

                      {/* Progress Bar */}
                      <Box sx={{ mt: 'auto' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.25 }}>
                          <Typography 
                            variant="caption" 
                            sx={{ 
                              color: 'rgba(255,255,255,0.7)', 
                              fontSize: '0.7rem' 
                            }}
                          >
                            Scene {story.current_node?.scene_number || 1} of 8
                          </Typography>
                        </Box>
                        <Box sx={{ 
                          display: 'flex', 
                          gap: '2px',
                          width: '100%',
                        }}>
                          {[...Array(8)].map((_, index) => (
                            <Box
                              key={index}
                              sx={{
                                flex: 1,
                                height: 3,
                                borderRadius: 1,
                                bgcolor: 'rgba(255,255,255,0.2)',
                                position: 'relative',
                                overflow: 'hidden',
                                '&::after': {
                                  content: '""',
                                  position: 'absolute',
                                  top: 0,
                                  left: 0,
                                  right: 0,
                                  bottom: 0,
                                  bgcolor: index < (story.current_node?.scene_number || 1)
                                    ? theme.palette.mode === 'dark'
                                      ? theme.palette.primary.main
                                      : theme.palette.primary.main
                                    : 'transparent',
                                  transition: 'background-color 0.3s ease'
                                }
                              }}
                            />
                          ))}
                        </Box>
                      </Box>
                    </CardContent>
                  </CardActionArea>
                </StyledCard>
              </Grid>
            ))}
          </Grid>
        )}

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          message={snackbar.message}
        />
      </Container>
    </Box>
  );
};

export default ClassDetail;