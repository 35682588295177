import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Alert, CircularProgress } from '@mui/material';
import axios from 'axios';
import { API_BASE_URL } from '../constants';

const ClassEnrollment: React.FC = () => {
  const [classCode, setClassCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const handleEnroll = async () => {
    if (!classCode.trim()) {
      setError('Class code is required');
      return;
    }

    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      await axios.post(`${API_BASE_URL}/classes/${classCode.trim()}/enroll/`);
      setSuccess('Successfully enrolled in the class!');
      setClassCode('');
      // Wait a bit before redirecting to show the success message
      setTimeout(() => {
        window.location.href = '/classes';
      }, 1500);
    } catch (error: any) {
      console.error('Error enrolling in class:', error);
      setError(error.response?.data?.error || 'Failed to enroll in class. Please check the class code and try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ pt: 10 }}>
      <Typography variant="h5" gutterBottom>Enroll in a Class</Typography>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}
      <TextField
        fullWidth
        label="Class Code"
        value={classCode}
        onChange={(e) => setClassCode(e.target.value)}
        margin="normal"
      />
      <Button 
        variant="contained" 
        onClick={handleEnroll}
        disabled={loading || !classCode}
      >
        {loading ? <CircularProgress size={24} /> : 'Enroll'}
      </Button>
    </Box>
  );
};

export default ClassEnrollment;