import React from 'react';
import {
  Box,
  Typography,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import {
  AutoStories as BookIcon,
  Psychology as BrainIcon,
  Group as UsersIcon,
  Grade as StarIcon,
  EmojiEvents as TrophyIcon,
} from "@mui/icons-material";

const IconBox = styled(Box)(({ theme }) => ({
  width: 48,
  height: 48,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  zIndex: 1,
  marginBottom: theme.spacing(2),
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'scale(1.1)',
  }
}));

const RoadmapSection = () => {
  const steps = [
    {
      icon: BookIcon,
      title: "Choose Your Story",
      description: "Start your journey by selecting education stories from our library or create a new personalized story",
    },
    {
      icon: BrainIcon,
      title: "Learn Through Choices",
      description: "Make decisions that shape your story while learning key concepts",
    },
    {
      icon: UsersIcon,
      title: "Join Classrooms",
      description: "Connect with teachers and classmates in private learning spaces",
    },
    {
      icon: StarIcon,
      title: "Track Progress",
      description: "Watch your knowledge grow through interactive storytelling",
    },
    {
      icon: TrophyIcon,
      title: "Master Concepts",
      description: "Complete stories to demonstrate your understanding",
    }
  ];

  return (
    <Box sx={{
      maxWidth: '1400px',
      mx: 'auto',
      px: { xs: 2, sm: 4 },
      mt: 12,
      mb: 8
    }}>
      <Box sx={theme => ({
        py: 6,
        background: theme.palette.mode === 'dark'
          ? 'rgba(18,18,18,0.97)'
          : 'rgba(252,253,254,0.97)',
        backdropFilter: 'blur(10px)',
        borderRadius: '20px',
        position: 'relative',
        overflow: 'hidden',
        boxShadow: theme.palette.mode === 'dark'
          ? '0 4px 20px rgba(0,0,0,0.2)'
          : '0 4px 20px rgba(0,0,0,0.06)',
      })}>
        <Box sx={{
          position: 'relative',
          zIndex: 1,
          px: { xs: 2, sm: 4 },
        }}>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: { xs: 'column', md: 'row' },
            gap: { xs: 4, sm: 3 },
            position: 'relative',
          }}>
            {steps.map((step, index) => (
              <Box
                key={step.title}
                sx={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  position: 'relative',
                  animation: `popIn 0.5s ease-out ${index * 0.2}s both`,
                  '@keyframes popIn': {
                    '0%': {
                      opacity: 0,
                      transform: 'scale(0.5) translateY(20px)'
                    },
                    '100%': {
                      opacity: 1,
                      transform: 'scale(1) translateY(0)'
                    }
                  }
                }}
              >
                <IconBox sx={theme => ({
                  background: theme.palette.mode === 'dark' ? '#333' : '#f5f5f5',
                })}>
                  <step.icon sx={theme => ({ 
                    color: theme.palette.mode === 'dark' ? '#fff' : '#000',
                    fontSize: 24
                  })} />
                </IconBox>

                <Box sx={{ textAlign: 'center', px: 2 }}>
                  <Typography variant="h6" sx={{ mb: 1, fontWeight: 600 }}>
                    {step.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {step.description}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RoadmapSection;