import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Typography, Grid, Card, CardContent, CardActionArea, CircularProgress, Alert } from '@mui/material';
import axios from 'axios';

interface StoryPreview {
  id: number;
  title: string;
  context: string;
  plays: number;
  likes: number;
  current_node?: {
    image_url: string;
  };
}

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';
const S3_BUCKET_URL = 'https://storybyte1.s3.amazonaws.com';
const FALLBACK_IMAGE = '/static/images/fallback_cover.png';

const ProfileStories: React.FC = () => {
  const [stories, setStories] = useState<StoryPreview[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const getImageUrl = (source: string | undefined | null): string => {
    if (!source) return FALLBACK_IMAGE;
    if (source.startsWith('http') || source.startsWith('data:')) return source;
    if (source.startsWith('/static/')) return source; // Local fallback image
    return `${S3_BUCKET_URL}/${source}`;
  };

  useEffect(() => {
    const fetchUserStories = async () => {
      setLoading(true);
      setError(null);
      try {
        const token = localStorage.getItem('token');
        console.log('Fetching user stories with token:', token ? 'Token exists' : 'No token');
        
        const response = await axios.get<StoryPreview[]>(`${API_BASE_URL}/stories/user_stories/`, {
          headers: { Authorization: `Token ${token}` }
        });
        
        console.log('API Response:', response);
        console.log('Fetched user stories:', response.data);
        
        if (Array.isArray(response.data)) {
          const parsedStories = response.data.map((story: any) => ({
            ...story,
            current_node: typeof story.current_node === 'string' ? JSON.parse(story.current_node) : story.current_node
          }));
          setStories(parsedStories);
        } else {
          console.error('Unexpected response format:', response.data);
          setError('Received unexpected data format from server');
        }
      } catch (error) {
        console.error('Error fetching user stories:', error);
        if (axios.isAxiosError(error)) {
          console.error('Error details:', error.response?.data);
          setError(`Failed to fetch user stories: ${error.response?.data?.detail || error.message}`);
        } else {
          setError('Failed to fetch user stories. Please try again later.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchUserStories();
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h5" gutterBottom>My Stories</Typography>
      {stories.length === 0 ? (
        <Typography>You haven't created any stories yet.</Typography>
      ) : (
        <Grid container spacing={4}>
          {stories.map((story) => (
            <Grid item xs={12} sm={6} md={4} key={story.id}>
              <Card sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 4,
                transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: 4,
                },
              }}>
                <CardActionArea 
                  component={RouterLink} 
                  to={`/story/${story.id}`}
                  sx={{ height: '100%' }}
                >
                  <Box sx={{ position: 'relative', paddingTop: '56.25%' }}>
                    <Box
                      component="img"
                      src={story.current_node?.image_url || '/placeholder-image.jpg'}
                      alt={story.title}
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                      }}
                    />
                  </Box>
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      {story.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {story.context.substring(0, 100)}...
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Plays: {story.plays} | Likes: {story.likes}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default ProfileStories;