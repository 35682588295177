import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  CircularProgress,
  Tabs,
  Tab,
  LinearProgress,
  Chip,
  IconButton,
  Tooltip,
  Alert,
  Paper,
  useTheme,
  Button,
  Avatar,
} from '@mui/material';
import {
  Bookmark,
  PlayArrow,
  Star,
  StarBorder,
  School,
  Public,
  Lock,
} from '@mui/icons-material';
import axios from 'axios';

interface Story {
  id: number;
  title: string;
  context: string;
  plays: number;
  likes: number;
  cover_image?: string;
  current_node?: {
    scene_number: number;
    image_url: string;
  };
  is_public: boolean;
  is_educational: boolean;
  rating: number;
  completion_percentage: number;
  cache_percentage: number;
  tags: string[];
  author?: string;
  user_progress?: any[];
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`library-tabpanel-${index}`}
      aria-labelledby={`library-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';
const S3_BUCKET_URL = 'https://storybyte1.s3.amazonaws.com';
const FALLBACK_IMAGE = '/static/images/fallback_cover.png';

const UnifiedLibrary: React.FC = () => {
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState(0);
  const [inProgressStories, setInProgressStories] = useState<Story[]>([]);
  const [completedStories, setCompletedStories] = useState<Story[]>([]);
  const [suggestedStories, setSuggestedStories] = useState<Story[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const getImageUrl = (source: string | undefined | null): string => {
    if (!source) return FALLBACK_IMAGE;
    if (source.startsWith('http') || source.startsWith('data:')) return source;
    if (source.startsWith('/static/')) return source;
    return `${S3_BUCKET_URL}/${source}`;
  };

  // Setup axios interceptor for authentication
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Token ${token}`;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }

    // Fetch stories when component mounts
    fetchStories();
  }, []);

  const fetchStories = async () => {
    setLoading(true);
    setError(null);
    try {
      // Fetch in-progress stories from continue reading endpoint
      const continueReadingResponse = await axios.get(`${API_BASE_URL}/stories/continue_reading/`);
      const inProgressData = continueReadingResponse.data || [];
      console.log('Continue Reading Response:', inProgressData);
      setInProgressStories(inProgressData);

      // Fetch personal library stories for completed section
      const personalResponse = await axios.get(`${API_BASE_URL}/stories/personal_library/`);
      const personalStories = personalResponse.data || [];
      console.log('Personal Library Response:', personalStories);
      
      // Filter completed stories
      const completed = personalStories.filter((story: Story) => {
        const progress = story.user_progress?.find((p: any) => p.completed === true);
        return progress !== undefined;
      }).sort((a: Story, b: Story) => b.plays - a.plays);
      setCompletedStories(completed);

      // Fetch suggested stories
      const suggestedResponse = await axios.get(`${API_BASE_URL}/stories/suggested/`);
      setSuggestedStories(suggestedResponse.data || []);
    } catch (error: any) {
      console.error('Error fetching library:', error);
      if (error.response?.status === 401) {
        setError('Please log in to view your library');
      } else {
        const errorMessage = error.response?.data?.detail || 'Failed to load your library. Please try again later.';
        setError(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };

  const safeRenderStoryCard = (story: Story) => {
    try {
      return renderStoryCard(story);
    } catch (error) {
      console.error('Error rendering story card:', error, story);
      return null;
    }
  };

  useEffect(() => {
    console.log('In Progress Stories State:', inProgressStories);
    console.log('Completed Stories State:', completedStories);
  }, [inProgressStories, completedStories]);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const renderStoryCard = (story: Story) => {
    console.log('Rendering story card:', story);
    return (
      <Grid 
        item 
        xs={12}
        sm={6}
        md={4} 
        lg={3}
        key={story.id}
      >
        <Card
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            borderRadius: 4,
            maxWidth: "320px",
            width: "100%",
            mx: 'auto'
          }}
        >
          <CardActionArea
            component={Link}
            to={`/story/${story.id}`}
          >
            <Box sx={{ position: 'relative', paddingTop: '56.25%' }}>
              <Box
                component="img"
                src={getImageUrl(story.current_node?.image_url || story.cover_image)}
                alt={story.title}
                onError={(e: any) => {
                  if (e.target.src !== FALLBACK_IMAGE) {
                    e.target.src = FALLBACK_IMAGE;
                  }
                }}
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
              {story.completion_percentage > 0 && (
                <Box 
                  sx={{ 
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: 4,
                    bgcolor: 'rgba(0,0,0,0.3)'
                  }}
                >
                  <Box 
                    sx={{ 
                      width: `${story.completion_percentage}%`,
                      height: '100%',
                      bgcolor: 'primary.main'
                    }} 
                  />
                </Box>
              )}
            </Box>
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography gutterBottom variant="h6" component="div">
                {story.title}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mb: 2 }}
              >
                {story.context.substring(0, 100)}...
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Chip
                  avatar={<Avatar>{story.author?.[0] || "A"}</Avatar>}
                  label={story.author || "Anonymous"}
                  variant="outlined"
                  sx={{ borderRadius: 4 }}
                />
                <Box>
                  <Chip
                    icon={<PlayArrow />}
                    label={`${story.plays}`}
                    size="small"
                    sx={{ mr: 1, borderRadius: 4 }}
                  />
                  <Chip
                    icon={<School />}
                    label={`${story.likes}`}
                    size="small"
                    sx={{ borderRadius: 4 }}
                  />
                </Box>
              </Box>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    );
  };

  if (loading) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        minHeight: '100vh',
        mt: 12, 
        width: '100%'
      }}>
        <CircularProgress sx={{ color: '#FF4F00' }} />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        {error}
      </Alert>
    );
  }

  return (
    <Box sx={{ 
      maxWidth: 'lg', 
      mx: 'auto', 
      px: 3,
      mt: 12, 
      width: '100%' 
    }}>
      <Box sx={{ mt: { xs: 4, md: 6 }, mb: 4 }} /> {/* Increased top spacing with responsive values */}

      <Box sx={{
        borderRadius: 2,
        overflow: 'hidden',
        bgcolor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.02)',
        boxShadow: theme.palette.mode === 'dark'
          ? '0 4px 20px rgba(0, 0, 0, 0.2)'
          : '0 4px 20px rgba(0, 0, 0, 0.05)'
      }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="library tabs"
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            bgcolor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.03)' : 'rgba(0, 0, 0, 0.01)',
            '& .MuiTabs-indicator': {
              backgroundColor: theme.palette.mode === 'dark' ? '#FF8A00' : '#FF4F00',
            },
            '& .MuiTab-root': {
              textTransform: 'none',
              fontSize: '1rem',
              fontWeight: 500,
              color: theme.palette.text.secondary,
              '&.Mui-selected': {
                color: theme.palette.mode === 'dark' ? '#FF8A00' : '#FF4F00',
              },
            },
          }}
        >
          <Tab
            label={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <PlayArrow sx={{ fontSize: 20 }} />
                <span>In Progress ({inProgressStories.length})</span>
              </Box>
            }
          />
          <Tab
            label={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Star sx={{ fontSize: 20 }} />
                <span>Completed ({completedStories.length})</span>
              </Box>
            }
          />
          <Tab
            label={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Bookmark sx={{ fontSize: 20 }} />
                <span>Suggested ({suggestedStories.length})</span>
              </Box>
            }
          />
        </Tabs>

        <TabPanel value={activeTab} index={0}>
          {inProgressStories.length > 0 ? (
            <Grid container spacing={3}>
              {inProgressStories.map(safeRenderStoryCard)}
            </Grid>
          ) : (
            <Paper
              sx={{
                p: 4,
                textAlign: 'center',
                bgcolor: 'transparent',
                border: '2px dashed',
                borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
              }}
            >
              <Typography variant="h6" color="text.secondary" gutterBottom>
                Start Your Journey
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
                You haven't started any stories yet. Check out our suggested stories to begin your adventure!
              </Typography>
              <Button
                variant="contained"
                onClick={() => setActiveTab(2)}
                sx={{
                  background: theme.palette.mode === 'dark'
                    ? 'linear-gradient(45deg, #FF8A00, #FF4F00)'
                    : '#FF4F00',
                  color: 'white',
                  textTransform: 'none',
                  px: 4,
                  py: 1.5,
                  borderRadius: 2,
                  '&:hover': {
                    background: theme.palette.mode === 'dark'
                      ? 'linear-gradient(45deg, #FF9500, #FF6000)'
                      : '#FF6000',
                  }
                }}
              >
                Explore Stories
              </Button>
            </Paper>
          )}
        </TabPanel>

        <TabPanel value={activeTab} index={1}>
          {completedStories.length > 0 ? (
            <Grid container spacing={3}>
              {completedStories.map(safeRenderStoryCard)}
            </Grid>
          ) : (
            <Paper
              sx={{
                p: 4,
                textAlign: 'center',
                bgcolor: 'transparent',
                border: '2px dashed',
                borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
              }}
            >
              <Typography variant="h6" color="text.secondary" gutterBottom>
                Complete Your First Story
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Finish a story to see it here and track your reading achievements!
              </Typography>
            </Paper>
          )}
        </TabPanel>

        <TabPanel value={activeTab} index={2}>
          {suggestedStories.length > 0 ? (
            <Grid container spacing={3}>
              {suggestedStories.map(safeRenderStoryCard)}
            </Grid>
          ) : (
            <Paper
              sx={{
                p: 4,
                textAlign: 'center',
                bgcolor: 'transparent',
                border: '2px dashed',
                borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
              }}
            >
              <Typography variant="h6" color="text.secondary" gutterBottom>
                Coming Soon
              </Typography>
              <Typography variant="body1" color="text.secondary">
                We're preparing personalized story recommendations just for you!
              </Typography>
            </Paper>
          )}
        </TabPanel>
      </Box>
    </Box>
  );
};

export default UnifiedLibrary;
