import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box, Typography, Button, Card, CardContent, CardMedia, Grid, Chip,
  IconButton, Alert, Snackbar, Paper, useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Favorite,
  FavoriteBorder,
  BookmarkAdd,
  BookmarkAdded,
  ArrowBack as ArrowBackIcon
} from '@mui/icons-material';
import axios from 'axios';
import StoryEnd from './StoryEnd';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';
const FALLBACK_IMAGE = '/static/images/fallback_cover.png';

const DEFAULT_NODE: StoryNode = {
  id: 0,  // Default ID for fallback node
  content: "Something went wrong. Please try again.",
  choices: ["Go back"],
  scene_number: 1,
  image_prompt: "",
  image_url: FALLBACK_IMAGE,
  summary: "Error state",
  characters: []
};

interface StoryData {
  id: number;
  title: string;
  current_node: StoryNode;
  current_scene: number;
  plays: number;
  likes: number;
  shares: number;
  cache_percentage: number;
  cover_image?: string;
  is_liked: boolean;
  is_saved: boolean;
  like_count: number;
  rating: number;
  num_ratings: number;
  comments: Array<{
    id: number;
    user?: {
      id: number;
      username: string;
    };
    text: string;
    created_at: string;
    rating: number;
  }>;
  nodes: StoryNode[];
}

interface StoryNode {
  content: string;
  choices: string[];
  scene_number: number;
  image_prompt: string;
  image_url: string;
  summary: string;
  characters: string[];
  parent_choice?: string;
  id: number;
}

interface FormattedTextProps {
  text: string;
}

const FormattedText: React.FC<FormattedTextProps> = ({ text }) => {
  const formatText = (content: string) => {
    if (!content) return null;

    // Handle case where content might be a JSON string
    let processedContent = content;
    if (typeof content === 'string') {
      try {
        const parsed = JSON.parse(content);
        if (parsed && typeof parsed === 'object' && parsed.content) {
          processedContent = parsed.content;
        }
      } catch (e) {
        // Not a JSON string, use as is
      }
    }
    
    const sentences = processedContent.split(/(\n+|"[^"]+?")/g);

    return sentences.map((part: string, index: number) => {
      if (!part?.trim()) {
        return null;
      }

      if (part.trim().startsWith('"') && part.trim().endsWith('"')) {
        const nextPart = sentences[index + 1] || '';
        const speakerMatch = nextPart.match(/,?\s*([\w\s]+)\s*(proclaimed|said|murmured|mused|chimed in|suggested|observed|whispered|asked|replied)/i);
        const speaker = speakerMatch ? speakerMatch[1].trim() : '';

        return (
          <div key={index} className="flex flex-col mb-4">
            <div className="flex items-start gap-3">
              {speaker && (
                <span className="text-sm font-semibold text-gray-800 mt-1 min-w-[100px] border-r pr-3">
                  {speaker}
                </span>
              )}
              <p className="text-lg flex-1 font-light italic">
                {part.trim()}
              </p>
            </div>
          </div>
        );
      } else if (part.trim()) {
        return (
          <p key={index} className="text-gray-700 mb-4 leading-relaxed text-lg">
            {part.trim()}
          </p>
        );
      }
      return null;
    }).filter(Boolean);
  };

  return (
    <div className="prose max-w-none">
      {text ? formatText(text) : null}
    </div>
  );
};

const StoryWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  width: '100%',
  position: 'relative',
  overflowX: 'hidden',
  paddingBottom: theme.spacing(4),
  backgroundColor: 'transparent'
}));

const StoryContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  paddingTop: theme.spacing(12),
  position: 'relative',
  zIndex: 1,
  maxWidth: '1200px',
  margin: '0 auto',
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '400px',
  borderRadius: theme.spacing(2),
  overflow: 'hidden',
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
  marginBottom: theme.spacing(4),
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}));

const StoryCard = styled(Card)(({ theme }) => ({
  background: theme.palette.mode === 'dark' 
    ? 'rgba(18, 18, 18, 0.8)'
    : 'rgba(255, 255, 255, 0.8)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.spacing(2),
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
  marginBottom: theme.spacing(4),
}));

const ChoiceButton = styled(Button)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderRadius: theme.spacing(2),
  textTransform: 'none',
  fontWeight: 500,
  fontSize: '1rem',
  background: theme.palette.mode === 'dark'
    ? '#1E293B'
    : '#FFFFFF',
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.2)' 
    : 'rgba(0, 0, 0, 0.12)'}`,
  boxShadow: theme.palette.mode === 'dark'
    ? '0 4px 12px rgba(0, 0, 0, 0.3)'
    : '0 4px 12px rgba(0, 0, 0, 0.1)',
  transition: 'all 0.3s ease',
  textAlign: 'center',
  '&:hover': {
    background: theme.palette.mode === 'dark'
      ? '#2D3748'
      : '#F8FAFC',
    transform: 'translateY(-2px)',
    boxShadow: theme.palette.mode === 'dark'
      ? '0 6px 16px rgba(0, 0, 0, 0.4)'
      : '0 6px 16px rgba(0, 0, 0, 0.15)',
  },
}));

const ChoicesSection = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

const Story: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const theme = useTheme();
  const [story, setStory] = useState<StoryData | null>(null);
  const [currentNode, setCurrentNode] = useState<StoryNode | null>(null);
  const [loading, setLoading] = useState(true);
  const [loadingStep, setLoadingStep] = useState('Loading story...');
  const [error, setError] = useState<string | null>(null);
  const [showEndModal, setShowEndModal] = useState(false);
  const [failedImages, setFailedImages] = useState<Set<string>>(new Set());
  const [playRecorded, setPlayRecorded] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });
  const [resetCounter, setResetCounter] = useState(0);

  const getImageUrl = useCallback((url: string | undefined): string => {
    if (!url) return FALLBACK_IMAGE;
    if (url.startsWith('http') || url.startsWith('data:')) return url;
    if (process.env.NODE_ENV === 'production') {
      return `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/${url}`;
    } else {
      return `/media/${url}`;
    }
  }, []);

  const fetchStory = useCallback(async () => {
    if (!id) return;
    setLoading(true);
    setLoadingStep('Connecting to server...');
    setError(null);
    try {
      setLoadingStep('Fetching story data...');
      const response = await axios.get<StoryData>(`${API_BASE_URL}/stories/${id}/`);
      setLoadingStep('Processing story content...');
      setStory(response.data);
      
      // Parse current_node if it's a string
      let parsedNode = response.data.current_node;
      if (typeof parsedNode === 'string') {
        try {
          parsedNode = JSON.parse(parsedNode);
        } catch (e) {
          console.error('Failed to parse current_node:', e);
          parsedNode = DEFAULT_NODE;
        }
      }
      setCurrentNode(parsedNode);
      console.log('Fetched story data:', response.data);
    } catch (error) {
      console.error('Error fetching story:', error);
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 404) {
          setError('Story not found or has been removed due to incompleteness.');
        } else if (error.response.status === 401) {
          navigate('/login', { state: { from: `/story/${id}` } });
        } else {
          setError(`Failed to fetch story: ${error.response.data.detail || error.response.statusText}`);
        }
      } else {
        setError('Failed to fetch story. Please try again later.');
      }
    } finally {
      setLoading(false);
    }
  }, [id, navigate]);

  useEffect(() => {
    fetchStory();
  }, [fetchStory, resetCounter]);

  useEffect(() => {
    if (currentNode && currentNode.image_url && !failedImages.has(currentNode.image_url)) {
      console.log('Current image URL:', getImageUrl(currentNode.image_url));
    }
  }, [currentNode, failedImages, getImageUrl]);

  const handlePlay = useCallback(async () => {
    if (!story || !id) return;
    setPlayRecorded(true);
  }, [id, story]);

  useEffect(() => {
    if (story && !playRecorded) {
      handlePlay();
    }
  }, [story, playRecorded, handlePlay]);

  const handleChoice = async (choiceIndex: number) => {
    if (!id) return;
    setLoading(true);
    setLoadingStep('Processing your choice...');
    setError(null);
    try {
      console.log('Making choice:', choiceIndex);
      const response = await axios.post<StoryData>(
        `${API_BASE_URL}/stories/${id}/make_choice/`,
        { choice_index: choiceIndex }
      );

      if (response.data && response.data.current_node) {
        // Parse current_node if it's a string
        let parsedNode = response.data.current_node;
        if (typeof parsedNode === 'string') {
          try {
            parsedNode = JSON.parse(parsedNode);
          } catch (e) {
            console.error('Failed to parse current_node:', e);
            parsedNode = DEFAULT_NODE;
          }
        }

        // Validate scene progression
        if (!parsedNode.scene_number || parsedNode.scene_number <= (story?.current_scene || 0)) {
          console.error('Invalid scene progression:', {
            current: story?.current_scene,
            new: parsedNode.scene_number
          });
          setError('Error progressing story. Please try again.');
          return;
        }

        // Update both states atomically
        setStory(prev => {
          if (!prev) return response.data;
          return {
            ...response.data,
            current_node: parsedNode
          };
        });
        setCurrentNode(parsedNode);

        console.log('Updated story state:', {
          scene: response.data.current_scene,
          node: parsedNode.scene_number,
          choices: parsedNode.choices,
          content: parsedNode.content.substring(0, 50) + '...' // Log first 50 chars
        });
      } else {
        console.error('Invalid response structure:', response.data);
        setError('Received invalid data from server. Please try again.');
      }
    } catch (error) {
      console.error('Error making choice:', error);
      if (axios.isAxiosError(error)) {
        const errorMessage = error.response?.data?.error || error.response?.statusText || 'Failed to process choice';
        setError(errorMessage);
        if (error.response.status === 404) {
          console.error('Endpoint not found:', error.response);
        }
      } else {
        setError('An unexpected error occurred while making choice');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleRestart = async () => {
    if (!id) return;
    setLoading(true);
    setLoadingStep('Resetting story...');
    setError(null);
    try {
      // Get the initial node from the database
      const initialNode = story?.nodes?.find(node => node.scene_number === 1);
      if (!initialNode) {
        throw new Error('Could not find initial node');
      }

      // Update story state
      const updatedStory = {
        ...story,
        current_node: initialNode,
        current_scene: 1
      };
      
      // Update server state
      await axios.post<StoryData>(`${API_BASE_URL}/stories/${id}/reset/`, {
        node_id: initialNode.id
      });
      
      // Update local state
      setStory(updatedStory);
      setCurrentNode(initialNode);
      setShowEndModal(false);
      setPlayRecorded(false);
      setResetCounter(prev => prev + 1);
      setError(null);
      console.log('Story reset successfully:', updatedStory);
    } catch (error) {
      console.error('Error resetting story:', error);
      if (axios.isAxiosError(error)) {
        const errorMessage = error.response?.data?.error || error.response?.statusText || 'Failed to reset story';
        setError(errorMessage);
      } else {
        setError('An unexpected error occurred while resetting the story');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleGoBack = async () => {
    if (!id || !story || story.current_scene <= 1) {
      setSnackbar({ open: true, message: "You are already at the beginning of the story." });
      return;
    }

    setLoading(true);
    setLoadingStep('Going to previous scene...');
    setError(null);

    try {
      console.log('Calling API:', `${API_BASE_URL}/stories/${id}/go_back/`);

      const response = await axios.post<StoryData>(
        `${API_BASE_URL}/stories/${id}/go_back/`,
        {}, // Empty body
        {
          headers: {
            'Content-Type': 'application/json',
            ...(localStorage.getItem('token') && {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            })
          },
          validateStatus: (status) => {
            return status >= 200 && status < 500; // Handle all responses
          }
        }
      );

      console.log('Go back response:', response.data);

      if (response.status === 200 && response.data) {
        setStory(response.data);
        
        // Parse current_node if it's a string
        let parsedNode = response.data.current_node;
        if (typeof parsedNode === 'string') {
          try {
            parsedNode = JSON.parse(parsedNode);
          } catch (e) {
            console.error('Failed to parse current_node:', e);
            parsedNode = DEFAULT_NODE;
          }
        }
        setCurrentNode(parsedNode);
        setResetCounter(prev => prev + 1);
        console.log('Updated story state after going back:', {
          scene: response.data.current_scene,
          node: parsedNode.scene_number,
          choices: parsedNode.choices
        });
      } else {
        throw new Error(
          (response.data as { error?: string })?.error || 'Failed to go back'
        );
      }
    } catch (error) {
      console.error('Error going back:', error);
      if (axios.isAxiosError(error)) {
        const errorMessage = error.response?.data?.error || error.response?.statusText || 'Failed to go back';
        setError(errorMessage);
        if (error.response.status === 404) {
          console.error('Endpoint not found:', error.response);
        }
      } else {
        setError('An unexpected error occurred while going back');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleLike = async () => {
    if (!story || !id) return;
    try {
      const response = await axios.post<StoryData>(`${API_BASE_URL}/stories/${id}/like/`);
      setStory(prevStory => prevStory ? ({
        ...prevStory,
        is_liked: response.data.is_liked,
        like_count: response.data.like_count
      }) : null);
      setSnackbar({ open: true, message: response.data.is_liked ? 'Story liked!' : 'Story unliked.' });
    } catch (error) {
      console.error('Error liking story:', error);
      if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
        navigate('/login', { state: { from: `/story/${id}` } });
      } else {
        setSnackbar({ open: true, message: 'Failed to like story. Please try again.' });
      }
    }
  };

  const handleSave = async () => {
    if (!story || !id) return;
    try {
      const response = await axios.post<StoryData>(`${API_BASE_URL}/stories/${id}/save/`);
      setStory(prevStory => prevStory ? ({
        ...prevStory,
        is_saved: response.data.is_saved
      }) : null);
      setSnackbar({ open: true, message: response.data.is_saved ? 'Story saved!' : 'Story unsaved.' });
    } catch (error) {
      console.error('Error saving story:', error);
      if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
        navigate('/login', { state: { from: `/story/${id}` } });
      } else {
        setSnackbar({ open: true, message: 'Failed to save story. Please try again.' });
      }
    }
  };

  if (loading) {
    console.log("Loading state triggered");
    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        gap: 2,
        backgroundColor: theme.palette.background.default
      }}>
        <Typography variant="body1" color="text.secondary">
          {loadingStep}
        </Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ mt: 4, maxWidth: 600, mx: 'auto', p: 3, backgroundColor: theme.palette.background.default }}>
        <Alert severity="error" sx={{ mb: 2, color: theme.palette.error.main, backgroundColor: theme.palette.error.light }}>{error}</Alert>
        <Button
          variant="outlined"
          onClick={() => navigate('/')}
          startIcon={<ArrowBackIcon />}
          sx={{ mt: 2, borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : '#1a1a1a', color: theme.palette.text.primary }}
        >
          Back to Home
        </Button>
      </Box>
    );
  }

  if (!story || !currentNode) {
    return (
      <Box sx={{ mt: 4, maxWidth: 600, mx: 'auto', p: 3, backgroundColor: theme.palette.background.default }}>
        <Alert severity="warning" sx={{ mb: 2, color: theme.palette.warning.main, backgroundColor: theme.palette.warning.light }}>Story not found or has no content.</Alert>
        <Button
          variant="outlined"
          onClick={() => navigate('/')}
          startIcon={<ArrowBackIcon />}
          sx={{ mt: 2, borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : '#1a1a1a', color: theme.palette.text.primary }}
        >
          Back to Home
        </Button>
      </Box>
    );
  }

  const isConclusion = story.current_scene === 8;

  if (isConclusion) {
    setShowEndModal(true);
    return null;
  }

  return (
    <>
      <StoryWrapper>
        <StoryContent>
          {/* Back Button */}
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate(-1)}
            sx={{
              mb: 3,
              color: 'text.primary',
              '&:hover': {
                background: 'rgba(0, 0, 0, 0.05)',
              },
            }}
          >
            Back
          </Button>

          {/* Story Header */}
          <Box sx={{ mb: 4 }}>
            <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 600 }}>
              {story.title}
            </Typography>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', flexWrap: 'wrap' }}>
              <Chip 
                label={`Scene ${story.current_scene || 1} of 8`}
                color="primary"
                variant="outlined"
              />
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <IconButton
                  onClick={handleLike}
                  color={story.is_liked ? 'secondary' : 'default'}
                >
                  {story.is_liked ? <Favorite /> : <FavoriteBorder />}
                </IconButton>
                <Typography variant="body2" color="text.secondary">
                  {story.like_count || 0}
                </Typography>
              </Box>
              <IconButton
                onClick={handleSave}
                color={story.is_saved ? 'primary' : 'default'}
              >
                {story.is_saved ? <BookmarkAdded /> : <BookmarkAdd />}
              </IconButton>
            </Box>
          </Box>

          {/* Story Image */}
          <ImageContainer>
            <img
              src={currentNode?.image_url || FALLBACK_IMAGE}
              alt={currentNode?.image_prompt || 'Story scene'}
              onError={(e) => {
                const img = e.target as HTMLImageElement;
                img.src = FALLBACK_IMAGE;
              }}
            />
          </ImageContainer>

          {/* Story Content */}
          <StoryCard>
            <CardContent sx={{ p: 4 }}>
              <FormattedText text={currentNode?.content || DEFAULT_NODE.content} />
            </CardContent>
          </StoryCard>

          {/* Choices Section */}
          <ChoicesSection>
            {currentNode?.choices.map((choice, index) => (
              <ChoiceButton
                key={index}
                onClick={() => handleChoice(index)}
                disabled={loading}
              >
                {choice}
              </ChoiceButton>
            ))}
          </ChoicesSection>

          {/* Navigation Buttons */}
          <Box sx={{ 
            mt: 4, 
            display: 'flex', 
            gap: 2,
            justifyContent: 'center',
            flexWrap: 'wrap'
          }}>
            <Button
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={handleGoBack}
              disabled={loading || story.current_scene <= 1}
              sx={{
                minWidth: 150,
                color: 'text.primary',
                borderColor: 'divider',
                '&:hover': {
                  borderColor: 'primary.main',
                  bgcolor: 'action.hover',
                },
              }}
            >
              Previous Scene
            </Button>
            <Button
              variant="outlined"
              onClick={handleRestart}
              sx={{
                minWidth: 150,
                color: 'text.primary',
                borderColor: 'divider',
                '&:hover': {
                  borderColor: 'primary.main',
                  bgcolor: 'action.hover',
                },
              }}
            >
              Restart Story
            </Button>
            <Button
              variant="outlined"
              onClick={() => navigate('/')}
              sx={{
                minWidth: 150,
                color: 'text.primary',
                borderColor: 'divider',
                '&:hover': {
                  borderColor: 'primary.main',
                  bgcolor: 'action.hover',
                },
              }}
            >
              Home
            </Button>
          </Box>
        </StoryContent>
      </StoryWrapper>

      {/* Story End Modal */}
      <StoryEnd
        open={showEndModal}
        onClose={() => setShowEndModal(false)}
        onRestart={handleRestart}
        title={story?.title || ''}
        storyData={{
          rating: story?.rating ?? 0,
          num_ratings: story?.num_ratings ?? 0,
          comments: story?.comments ?? []
        }}
      />

      {/* Loading and Error Snackbars */}
      <Snackbar
        open={loading}
        message="Loading next scene..."
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError('')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity="error" onClose={() => setError('')}>
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Story;