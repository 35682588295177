import React, { useState } from 'react';
import { useNavigate, useLocation, Link as RouterLink } from 'react-router-dom';
import { Typography, Button, Box, TextField, Divider, Link, Paper } from '@mui/material';
import axios from 'axios';
import { setupAxios } from '../utils/auth';
import { API_BASE_URL } from '../config';
import { useTheme } from '@mui/material/styles';

interface LoginProps {
  onLogin: () => void;
}

interface LocationState {
  from: string;
}

const Login: React.FC<LoginProps> = ({ onLogin }) => {
  const [identifier, setIdentifier] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    try {
      const response = await axios.post(`${API_BASE_URL}/accounts/login/`, {
        username: identifier, // Backend will handle both email and username
        password
      });
      
      localStorage.setItem('token', response.data.token);
      setupAxios();
      onLogin();
      const state = location.state as LocationState;
      navigate(state?.from || '/', { replace: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        console.error('Login error response:', error.response);
        setError(error.response.data.detail || 'Invalid credentials');
      } else {
        console.error('Unexpected error:', error);
        setError('An unexpected error occurred');
      }
    }
  };

  const handleGoogleLogin = () => {
    // Implement Google login logic here
  };

  return (
    <Box 
      sx={{ 
        mt: { xs: 12, sm: 14 }, // Increased margin-top for navbar spacing
        mx: 'auto', // Center horizontally
        px: 2, // Horizontal padding for mobile
        maxWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Paper 
        elevation={3}
        sx={{
          p: 4,
          maxWidth: 400,
          width: '100%',
          borderRadius: 2,
          bgcolor: theme.palette.mode === 'dark' ? 'background.paper' : 'rgba(255, 255, 255, 0.98)',
          backdropFilter: 'blur(10px)',
          border: theme.palette.mode === 'dark' ? '1px solid rgba(255, 255, 255, 0.1)' : 'none'
        }}
      >
        <Typography 
          variant="h4" 
          component="h1" 
          gutterBottom
          sx={{
            mb: 4,
            fontFamily: 'source-code-pro, monospace',
            fontWeight: 'bold',
            textAlign: 'center',
            color: theme.palette.mode === 'dark' ? 'text.primary' : '#1a1a1a'
          }}
        >
          Welcome Back
        </Typography>

        {error && (
          <Typography 
            color="error" 
            sx={{ 
              mb: 2,
              textAlign: 'center',
              backgroundColor: theme.palette.mode === 'dark' 
                ? 'rgba(211, 47, 47, 0.2)' 
                : 'rgba(211, 47, 47, 0.1)',
              p: 1,
              borderRadius: 1
            }}
          >
            {error}
          </Typography>
        )}

        <Box 
          component="form" 
          onSubmit={handleSubmit}
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <TextField
            fullWidth
            label="Email or Username"
            value={identifier}
            onChange={(e) => setIdentifier(e.target.value)}
            required
            autoComplete="username"
            placeholder="Enter your email or username"
            sx={{
              '& .MuiOutlinedInput-root': {
                bgcolor: theme.palette.mode === 'dark' ? 'background.paper' : 'white',
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.palette.primary.main,
                },
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: theme.palette.primary.main,
              },
              mb: 2
            }}
          />

          <TextField
            fullWidth
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            autoComplete="current-password"
            sx={{
              '& .MuiOutlinedInput-root': {
                bgcolor: theme.palette.mode === 'dark' ? 'background.paper' : 'white',
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.palette.primary.main,
                },
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: theme.palette.primary.main,
              },
              mb: 3
            }}
          />

          <Button
            type="submit"
            fullWidth
            sx={{
              background: 'linear-gradient(45deg, #2196F3, #1976D2)',
              color: 'white',
              py: 1.5,
              borderRadius: 2,
              textTransform: 'none',
              fontWeight: 600,
              fontSize: '1rem',
              boxShadow: '0 4px 15px rgba(33, 150, 243, 0.3)',
              backdropFilter: 'blur(10px)',
              border: 'none',
              transition: 'all 0.3s ease',
              '&:hover': {
                background: 'linear-gradient(45deg, #42A5F5, #1E88E5)',
                boxShadow: '0 6px 20px rgba(33, 150, 243, 0.4)',
                transform: 'translateY(-2px)',
              },
              '&:active': {
                transform: 'translateY(1px)',
                boxShadow: '0 2px 10px rgba(33, 150, 243, 0.2)',
              }
            }}
          >
            Login
          </Button>

          <Box sx={{ mt: 2, textAlign: 'center' }}>
            <Link
              component={RouterLink}
              to="/forgot-password"
              sx={{
                color: theme => theme.palette.primary.main,
                textDecoration: 'none',
                fontWeight: 600,
                fontSize: '0.875rem',
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              Forgot password?
            </Link>
          </Box>

          <Divider sx={{ my: 2 }}>
            <Typography 
              variant="body2" 
              sx={{ 
                color: theme => theme.palette.mode === 'dark' ? 'text.secondary' : 'text.primary',
              }}
            >
              OR
            </Typography>
          </Divider>

          <Button
            component={RouterLink}
            to="/signup"
            variant="outlined"
            fullWidth
            sx={{
              py: 1.5,
              borderRadius: 2,
              textTransform: 'none',
              fontSize: '1rem',
              fontWeight: 500,
              color: theme => theme.palette.mode === 'dark' ? 'white' : '#1a1a1a',
              borderColor: theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)',
              bgcolor: theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'transparent',
              '&:hover': {
                bgcolor: theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)',
                borderColor: theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)',
              }
            }}
          >
            New user? Sign up here
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default Login;