// Utility function to normalize text for consistent comparison
const normalizeText = (text: string): string => {
  return text.toLowerCase().trim();
};

// Common patterns that might indicate attempts to bypass the filter
const suspiciousPatterns = [
  /\b[a-z]+[\W_]+[a-z]+\b/i,  // Words with special characters between them
];

// List of inappropriate words (this is a minimal example, should be expanded)
const allBadWords: string[] = [
  // Add your list of inappropriate words here
  "inappropriate",
  "offensive",
  "profanity"
];

/**
 * Checks if the given text contains profanity or inappropriate content
 * @param text The text to check for profanity
 * @param strict If true, applies stricter rules for usernames and public content
 * @returns boolean indicating if profanity was detected
 */
export const containsProfanity = (text: string, strict: boolean = false): boolean => {
  if (!text?.trim()) return false;

  const normalizedText = normalizeText(text);
  // Split into words and remove empty strings
  const words = text.toLowerCase().split(/\W+/).filter(Boolean);
  
  // Check each word against the bad words list
  const hasBadWord = words.some(word => {
    // Normalize the word
    const normalizedWord = normalizeText(word);
    
    // Check if it's an exact match with any bad word
    return allBadWords.some(badWord => {
      const normalizedBadWord = normalizeText(badWord);
      // Only match if it's a complete word match
      return normalizedWord === normalizedBadWord;
    });
  });

  if (hasBadWord) return true;

  // Apply stricter rules for usernames and public content
  if (strict) {
    // Check for suspicious patterns that might indicate attempts to bypass the filter
    if (suspiciousPatterns.some(pattern => pattern.test(text))) {
      return true;
    }

    // Check for hidden profanity (e.g., "f.u.c.k" or "f-u-c-k")
    const withoutSpecialChars = text.replace(/[^a-zA-Z0-9\s]/g, '').toLowerCase();
    const wordsWithoutSpecialChars = withoutSpecialChars.split(/\s+/).filter(Boolean);
    
    return wordsWithoutSpecialChars.some(word => 
      allBadWords.some(badWord => {
        const cleanBadWord = badWord.replace(/[^a-zA-Z0-9]/g, '');
        // Only match if it's a complete word match
        return word === cleanBadWord;
      })
    );
  }

  return false;
};

/**
 * Filter out profanity from the given text by replacing with asterisks
 * @param text Text to filter
 * @returns Filtered text with profanity replaced by asterisks
 */
export const filterProfanity = (text: string): string => {
  if (!text) return text;
  
  let filteredText = text;
  allBadWords.forEach(word => {
    const regex = new RegExp(`\\b${word}\\b`, 'gi');
    filteredText = filteredText.replace(regex, '*'.repeat(word.length));
  });
  
  return filteredText;
};
