import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material';
import { 
  TextField, 
  Button, 
  Typography, 
  Box, 
  CircularProgress, 
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  Alert,
  Snackbar,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Card,
  CardContent,
  styled,
  Grid,
  Chip
} from '@mui/material';
import { 
  Create,
  AutoStories,
  Psychology,
  Settings,
  ArrowForward,
  ArrowBack
} from '@mui/icons-material';
import axios from 'axios';

// Styled components
const StoryCreationContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '800px',
  margin: '0 auto',
  padding: theme.spacing(4),
  paddingTop: theme.spacing(8),
  marginTop: theme.spacing(4),
}));

const StepContent = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: theme.spacing(4),
}));

const LearningStyleCard = styled(Card)(({ theme }) => ({
  cursor: 'pointer',
  transition: 'all 0.2s ease-in-out',
  background: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(2),
  borderRadius: '16px',
  color: theme.palette.text.primary,
  '& .MuiTypography-root': {
    color: theme.palette.text.primary,
  },
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[2],
    borderColor: theme.palette.primary.main,
  },
  '&.selected': {
    background: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    '& .MuiTypography-root': {
      color: theme.palette.primary.contrastText
    },
    color: theme.palette.primary.contrastText,
  },
}));

const StoryTypeIcon = styled(Box)(({ theme }) => ({
  fontSize: '1.75rem',
  padding: theme.spacing(1.5),
  borderRadius: '12px',
  background: theme.palette.action.hover,
  color: theme.palette.text.primary,
  marginRight: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all 0.2s ease-in-out',
  '.selected &': {
    background: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  }
}));

const StoryModeCard = styled(Card)(({ theme }) => ({
  cursor: 'pointer',
  transition: 'all 0.2s ease-in-out',
  background: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  height: '100%',
  minHeight: '200px',
  padding: theme.spacing(2),
  borderRadius: '16px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  color: theme.palette.text.primary,
  '& .MuiTypography-root': {
    color: theme.palette.text.primary,
  },
  '& .MuiChip-root': {
    backgroundColor: theme.palette.mode === 'dark' 
      ? 'rgba(255, 255, 255, 0.1)' 
      : 'rgba(25, 118, 210, 0.1)',
    color: theme.palette.mode === 'dark' 
      ? 'rgba(255, 255, 255, 0.9)' 
      : theme.palette.primary.main,
  },
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[2],
    borderColor: theme.palette.primary.main,
  },
  '&.selected': {
    background: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    '& .MuiTypography-root': {
      color: theme.palette.primary.contrastText
    },
    color: theme.palette.primary.contrastText,
    '& .MuiChip-root': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      color: theme.palette.primary.contrastText,
    }
  },
}));

const ModeIcon = styled(Box)(({ theme }) => ({
  fontSize: '1.5rem',
  padding: theme.spacing(1),
  borderRadius: '12px',
  background: theme.palette.action.hover,
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(1.5),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all 0.2s ease-in-out',
  '.selected &': {
    background: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  }
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '24px',
  padding: '10px 24px',
  fontWeight: 600,
  textTransform: 'none',
  fontSize: '1rem',
  boxShadow: 'none',
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[4],
  },
  '& .emoji': {
    fontSize: '1.2rem',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(-0.5),
  },
  '& .back-emoji': {
    fontSize: '1.2rem',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(-0.5),
  },
  '& .forward-emoji': {
    fontSize: '1.2rem',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(-0.5),
  },
}));

// Types
interface Class {
  id: number;
  name: string;
  stories_count: number;
  owner: string;
}

interface StoryData {
  title: string;
  context: string;
  education_level: string;
  class_id: number | null;
  is_public: boolean;
  is_educational: boolean;
  tags: string[];
  learning_style?: string;
  story_modes?: string[];
}

const educationLevels = [
  "General",
  "Elementary",
  "Middle School",
  "High School",
  "College",
  "Professional"
];

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';

const storyBasedModes = [
  {
    id: 'historical_reenactment',
    name: 'Historical Reenactment',
    description: 'Experience history through the eyes of those who lived it',
    icon: '🏰',
    subjects: ['History', 'Social Studies', 'Cultural Studies'],
    learningStyle: 'story_based'
  },
  {
    id: 'scientific_discovery',
    name: 'Scientific Discovery',
    description: 'Follow the journey of scientific breakthroughs',
    icon: '🔬',
    subjects: ['Physics', 'Chemistry', 'Biology'],
    learningStyle: 'story_based'
  },
  {
    id: 'mathematical_quest',
    name: 'Mathematical Quest',
    description: 'Solve real-world problems using mathematics',
    icon: '📐',
    subjects: ['Geometry', 'Algebra', 'Statistics'],
    learningStyle: 'story_based'
  },
  {
    id: 'literary_analysis',
    name: 'Literary Analysis',
    description: 'Create and analyze stories with literary experts',
    icon: '📚',
    subjects: ['Literature', 'Writing', 'Poetry'],
    learningStyle: 'story_based'
  },
  {
    id: 'environmental_systems',
    name: 'Environmental Systems',
    description: 'Explore ecological connections and consequences',
    icon: '🌍',
    subjects: ['Environmental Science', 'Ecology', 'Climate Science'],
    learningStyle: 'story_based'
  },
  {
    id: 'engineering_design',
    name: 'Engineering Design',
    description: 'Design and build solutions to engineering challenges',
    icon: '⚙️',
    subjects: ['Engineering', 'Architecture', 'Technology'],
    learningStyle: 'story_based'
  }
];

const interactiveLectureModes = [
  {
    id: 'quick_wiki',
    name: 'Quick Wiki',
    description: 'Direct, encyclopedia-style content delivery with no educational framing',
    icon: '📖',
    subjects: ['All Subjects'],
    learningStyle: 'interactive_lecture'
  },
  {
    id: 'guided_discussion',
    name: 'Guided Discussion',
    description: 'Interactive discussions with thought-provoking questions and real-time feedback',
    icon: '💭',
    subjects: ['Critical Thinking', 'Communication', 'Debate'],
    learningStyle: 'interactive_lecture'
  },
  {
    id: 'concept_mapping',
    name: 'Concept Mapping',
    description: 'Build and explore connections between key concepts and ideas',
    icon: '🔄',
    subjects: ['Visual Learning', 'Organization', 'Synthesis'],
    learningStyle: 'interactive_lecture'
  },
  {
    id: 'case_study',
    name: 'Case Study Analysis',
    description: 'Deep dive into real-world examples with guided analysis',
    icon: '🔍',
    subjects: ['Analysis', 'Problem Solving', 'Application'],
    learningStyle: 'interactive_lecture'
  },
  {
    id: 'problem_based_learning',
    name: 'Problem Based Learning',
    description: 'Learn by solving real-world problems with structured guidance',
    icon: '💡',
    subjects: ['Teaching', 'Leadership', 'Collaboration'],
    learningStyle: 'interactive_lecture'
  },
  {
    id: 'interactive_assessment',
    name: 'Interactive Assessment',
    description: 'Engage with dynamic quizzes and real-time feedback',
    icon: '✅',
    subjects: ['Self-Assessment', 'Knowledge Check', 'Feedback'],
    learningStyle: 'interactive_lecture'
  }
];

// Combine all modes
const storyModes = [...storyBasedModes, ...interactiveLectureModes];

const NewStory: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState<StoryData>({
    title: '',
    context: '',
    education_level: 'General',
    class_id: null,
    is_public: false,
    is_educational: true,
    tags: [],
    learning_style: 'story_based',
    story_modes: []
  });
  const [classes, setClasses] = useState<Class[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [snackbar, setSnackbar] = useState({ 
    open: false, 
    message: '', 
    severity: 'success' as 'success' | 'error' | 'warning' 
  });
  const navigate = useNavigate();

  // Steps configuration
  const steps = ['Select Learning Style', 'Choose Story Mode', 'Story Details'];

  useEffect(() => {
    const urlTitle = searchParams.get('title');
    if (urlTitle) {
      setFormData(prev => ({ ...prev, title: decodeURIComponent(urlTitle) }));
    }
    fetchClasses();
  }, [searchParams]);

  const fetchClasses = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/classes/my_classes/`);
      // Only use owned classes, not enrolled classes
      const ownedClasses = response.data.owned_classes;
      setClasses(ownedClasses);
    } catch (error) {
      console.error('Error fetching classes:', error);
      setSnackbar({ open: true, message: 'Failed to fetch classes. Please try again.', severity: 'error' });
    }
  };

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    try {
      setLoading(true);
      setError(null);

      const response = await axios.post(`${API_BASE_URL}/stories/`, formData);
      navigate(`/story/${response.data.id}`);
    } catch (error: any) {
      setError(error.response?.data?.message || 'An error occurred while creating the story.');
      setSnackbar({
        open: true,
        message: 'Failed to create story. Please try again.',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFormData(state => ({
      ...state,
      title: value
    }));
  };

  const handleContextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFormData(state => ({
      ...state,
      context: value
    }));
  };

  // Step content components
  const LearningStyleStep = () => (
    <StepContent>
      <Typography variant="h6" gutterBottom>
        Select Your Learning Style
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <LearningStyleCard 
            className={formData.learning_style === 'story_based' ? 'selected' : ''}
            onClick={() => setFormData(prev => ({ ...prev, learning_style: 'story_based' }))}
          >
            <CardContent sx={{ p: 3 }}>
              <Box display="flex" alignItems="center">
                <StoryTypeIcon>
                  <Typography variant="h4" component="span" role="img" aria-label="book">
                    📚
                  </Typography>
                </StoryTypeIcon>
                <Box>
                  <Typography 
                    variant="h6" 
                    sx={{ 
                      mb: 0.5 
                    }}
                  >
                    Story-Based Learning
                  </Typography>
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      mb: 2
                    }}
                  >
                    Learn through interactive stories and adventures
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </LearningStyleCard>
        </Grid>
        <Grid item xs={12}>
          <LearningStyleCard 
            className={formData.learning_style === 'interactive_lecture' ? 'selected' : ''}
            onClick={() => setFormData(prev => ({ ...prev, learning_style: 'interactive_lecture' }))}
          >
            <CardContent sx={{ p: 3 }}>
              <Box display="flex" alignItems="center">
                <StoryTypeIcon>
                  <Typography variant="h4" component="span" role="img" aria-label="lecture">
                    🎓
                  </Typography>
                </StoryTypeIcon>
                <Box>
                  <Typography 
                    variant="h6" 
                    sx={{ 
                      mb: 0.5 
                    }}
                  >
                    Interactive Lecture
                  </Typography>
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      mb: 2
                    }}
                  >
                    Engage with dynamic lecture-style content with interactive elements
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </LearningStyleCard>
        </Grid>
      </Grid>
    </StepContent>
  );

  const AddOnsStep = () => (
    <StepContent>
      <Typography variant="h6" gutterBottom>
        Choose Story Mode
      </Typography>
      <Typography variant="body2" color="textSecondary" sx={{ mb: 3 }}>
        Select a story mode to define your learning experience.
      </Typography>
      <Grid container spacing={3}>
        {storyModes
          .filter(mode => mode.learningStyle === formData.learning_style)
          .map((mode) => (
          <Grid item xs={12} sm={6} key={mode.id}>
            <StoryModeCard
              className={formData.story_modes?.includes(mode.id) ? 'selected' : ''}
              onClick={() => {
                setFormData(prev => {
                  const currentModes = prev.story_modes || [];
                  if (currentModes.includes(mode.id)) {
                    return {
                      ...prev,
                      story_modes: []
                    };
                  }
                  return {
                    ...prev,
                    story_modes: [mode.id]
                  };
                });
              }}
            >
              <CardContent sx={{ p: 3, height: '100%' }}>
                <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
                  <ModeIcon>
                    <Typography variant="h4" component="span" role="img" aria-label={mode.name}>
                      {mode.icon}
                    </Typography>
                  </ModeIcon>
                  <Typography 
                    variant="h6" 
                    sx={{ 
                      mb: 1
                    }}
                  >
                    {mode.name}
                  </Typography>
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      mb: 2
                    }}
                  >
                    {mode.description}
                  </Typography>
                  <Box 
                    sx={{ 
                      display: 'flex', 
                      flexWrap: 'wrap', 
                      gap: 0.5, 
                      justifyContent: 'center'
                    }}
                  >
                    {mode.subjects.map((subject) => (
                      <Chip
                        key={subject}
                        label={subject}
                        size="small"
                        sx={{
                          bgcolor: theme => theme.palette.mode === 'dark' 
                            ? 'rgba(255, 255, 255, 0.1)' 
                            : 'rgba(25, 118, 210, 0.1)',
                          color: theme => theme.palette.mode === 'dark' 
                            ? 'rgba(255, 255, 255, 0.9)' 
                            : 'primary.main'
                        }}
                      />
                    ))}
                  </Box>
                </Box>
              </CardContent>
            </StoryModeCard>
          </Grid>
        ))}
      </Grid>
    </StepContent>
  );

  const DetailsStep = () => (
    <Box>
      <Typography variant="h6" gutterBottom>
        Story Details
      </Typography>
      <Box display="flex" flexDirection="column" gap={3}>
        <TextField
          label="Title"
          defaultValue={formData.title}
          onBlur={(e) => {
            setFormData(prev => ({
              ...prev,
              title: e.target.value
            }));
          }}
          required
          fullWidth
        />
        <TextField
          label="Context"
          defaultValue={formData.context}
          onBlur={(e) => {
            setFormData(prev => ({
              ...prev,
              context: e.target.value
            }));
          }}
          required
          multiline
          rows={4}
          fullWidth
        />
        <FormControl fullWidth>
          <InputLabel>Education Level</InputLabel>
          <Select
            value={formData.education_level}
            onChange={(e: SelectChangeEvent) => {
              setFormData({ ...formData, education_level: e.target.value });
            }}
            label="Education Level"
          >
            {educationLevels.map((level) => (
              <MenuItem key={level} value={level}>{level}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {classes.length > 0 && (
          <FormControl fullWidth>
            <InputLabel>Class</InputLabel>
            <Select
              value={formData.class_id?.toString() || ''}
              onChange={(e: SelectChangeEvent) => {
                const newValue = e.target.value;
                setFormData({ 
                  ...formData, 
                  class_id: newValue ? Number(newValue) : null,
                  is_public: newValue ? false : formData.is_public
                });
              }}
              label="Class"
            >
              <MenuItem value="">No Class</MenuItem>
              {classes.map((cls) => (
                <MenuItem key={cls.id} value={cls.id.toString()}>{cls.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <FormControlLabel
          control={
            <Switch
              checked={formData.is_public}
              onChange={(e) => {
                setFormData({ ...formData, is_public: e.target.checked });
              }}
            />
          }
          label="Make Public"
        />
      </Box>
    </Box>
  );

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <LearningStyleStep />;
      case 1:
        return <AddOnsStep />;
      case 2:
        return <DetailsStep />;
      default:
        return 'Unknown step';
    }
  };

  return (
    <StoryCreationContainer>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom align="center">
          Create New Story
        </Typography>
        
        <Stepper activeStep={activeStep} sx={{ my: 4 }}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel StepIconComponent={() => (
                <Typography 
                  variant="h6" 
                  component="span" 
                  sx={{ 
                    opacity: activeStep >= index ? 1 : 0.5,
                    transition: 'opacity 0.2s ease-in-out'
                  }}
                >
                  {index === 0 ? '🎯' : index === 1 ? '🎨' : '📝'}
                </Typography>
              )}>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        {getStepContent(activeStep)}

        <ButtonContainer>
          <StyledButton
            onClick={handleBack}
            disabled={activeStep === 0}
            sx={{
              bgcolor: 'background.paper',
              color: 'text.primary',
              border: '1px solid',
              borderColor: 'divider',
              '&:hover': {
                bgcolor: 'action.hover',
              },
            }}
          >
            <span className="back-emoji" role="img" aria-label="back">
              ⬅️
            </span>
            Back
          </StyledButton>
          <Box>
            {activeStep === steps.length - 1 ? (
              <StyledButton
                variant="contained"
                onClick={handleSubmit}
                disabled={loading}
                sx={{
                  background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
                  color: 'white',
                }}
              >
                <span className="emoji" role="img" aria-label="sparkles">
                  ✨
                </span>
                {loading ? 'Creating...' : 'Create Story'}
                {loading && <CircularProgress size={20} sx={{ ml: 1, color: 'white' }} />}
              </StyledButton>
            ) : (
              <StyledButton
                variant="contained"
                onClick={handleNext}
                sx={{
                  background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
                  color: 'white',
                }}
              >
                Next
                <span className="forward-emoji" role="img" aria-label="forward">
                  ➡️
                </span>
              </StyledButton>
            )}
          </Box>
        </ButtonContainer>

        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Paper>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
      >
        <Alert severity={snackbar.severity} onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </StoryCreationContainer>
  );
};

export default NewStory;